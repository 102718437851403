import React from "react";
import DuckDBIcon from "../../assets/ductdb_dbt.png";
import s from "./Connections.module.scss";
import ConnectionField from "./ConnectionField";
import { RadioButton } from "primereact/radiobutton";
import { VirtualScroller } from "primereact/virtualscroller";

const DuckDBConnectionForm = ({
  duckDBRadio,
  setDuckDBRadio,
  selectedConnection,
  setSelectedConnection,
  submitted,
  isAddSelected,
}) => (
  <>
    <img src={DuckDBIcon} alt="Iceberg" className={s.duckdbicon} />
    <ConnectionField
      labelName="Connection Name"
      value={selectedConnection.db_conn_name}
      onChange={(e) => {
        setSelectedConnection({
          ...selectedConnection,
          db_conn_name: e.target.value,
        });
      }}
      id="db_conn_name"
      submitted={submitted}
      isInvalid={submitted && !selectedConnection.db_conn_name}
      tabIndex={1}
    >
      <div className="invalid-feedback">
        A valid connection name is required!
      </div>
    </ConnectionField>

    <div className={s.dbheading}>Duck DB</div>
    <ConnectionField
      labelName="Duck DB Database Name"
      value={selectedConnection.duckdb_database}
      onChange={(e) => {
        setSelectedConnection({
          ...selectedConnection,
          duckdb_database: e.target.value,
        });
      }}
      id="duckdb_database"
      submitted={submitted}
      isInvalid={submitted && !selectedConnection.duckdb_database}
      tabIndex={2}
    >
      <div className="invalid-feedback">A valid database name is required!</div>
    </ConnectionField>

    <div className="d-flex">
      <div className="col-md-6 col-lg-6">
        <RadioButton
          inputId="s3"
          name="radio"
          value="s3"
          onChange={(e) => setDuckDBRadio(e.value)}
          checked={duckDBRadio === "s3"}
        />
        <label htmlFor="s3" className={s.radiolabel}>
          Place DuckDB Database on S3
        </label>
      </div>
      <div className={`col-md-6 col-lg-6 ${s.ml_20}`}>
        <RadioButton
          inputId="local"
          name="radio"
          value="local"
          onChange={(e) => setDuckDBRadio(e.value)}
          checked={duckDBRadio === "local"}
        />
        <label htmlFor="local" className={s.radiolabel}>
          Place DuckDB Database on Local Wizbi Server
        </label>
      </div>
    </div>

    {duckDBRadio === "s3" && isAddSelected && (
      <div className="row d-flex">
        <ConnectionField
          labelName="Access Key ID"
          value={selectedConnection.s3_access_key_id}
          onChange={(e) => {
            setSelectedConnection({
              ...selectedConnection,
              s3_access_key_id: e.target.value,
            });
          }}
          id="s3_access_key_id"
          submitted={submitted}
          isInvalid={submitted && !selectedConnection.s3_access_key_id}
          tabIndex={3}
          className="col-md-6"
          panelClass="my-2"
        >
          <div className="invalid-feedback">
            A valid access key id is required!
          </div>
        </ConnectionField>
        <ConnectionField
          labelName="Secret Access Key"
          value={selectedConnection.s3_secret_access_key}
          onChange={(e) => {
            setSelectedConnection({
              ...selectedConnection,
              s3_secret_access_key: e.target.value,
            });
          }}
          id="s3_secret_access_key"
          submitted={submitted}
          isInvalid={submitted && !selectedConnection.s3_secret_access_key}
          tabIndex={4}
          className="col-md-6"
          panelClass="my-2"
        >
          <div className="invalid-feedback">
            A valid secret access key is required!
          </div>
        </ConnectionField>
        <ConnectionField
          labelName="Bucket"
          value={selectedConnection.s3_bucket}
          onChange={(e) => {
            setSelectedConnection({
              ...selectedConnection,
              s3_bucket: e.target.value,
            });
          }}
          id="s3_bucket"
          submitted={submitted}
          isInvalid={submitted && !selectedConnection.s3_bucket}
          tabIndex={5}
          className="col-md-6"
          panelClass="my-2"
        >
          <div className="invalid-feedback">A valid bucket is required!</div>
        </ConnectionField>
        <ConnectionField
          labelName="Bucket Region"
          value={selectedConnection.s3_bucket_region}
          onChange={(e) => {
            setSelectedConnection({
              ...selectedConnection,
              s3_bucket_region: e.target.value,
            });
          }}
          id="s3_bucket_region"
          submitted={submitted}
          isInvalid={submitted && !selectedConnection.s3_bucket_region}
          tabIndex={6}
          className="col-md-6"
          panelClass="my-2"
        >
          <div className="invalid-feedback">
            A valid bucket region is required!
          </div>
        </ConnectionField>
      </div>
    )}

    <ConnectionField
      labelName="Path/Folder"
      value={selectedConnection.duckdb_lfs_path}
      onChange={(e) => {
        setSelectedConnection({
          ...selectedConnection,
          duckdb_lfs_path: e.target.value,
        });
      }}
      id="duckdb_lfs_path"
      submitted={submitted}
      isInvalid={false}
      tabIndex={7}
      className="col-md-6"
      panelClass="my-2"
    />

    <hr />

    <div className={s.dbheading}>DBT</div>
    <ConnectionField
      labelName="DBT Project Name"
      value={selectedConnection.dbt_project_name}
      onChange={(e) => {
        setSelectedConnection({
          ...selectedConnection,
          dbt_project_name: e.target.value,
        });
      }}
      id="dbt_project_name"
      submitted={submitted}
      isInvalid={submitted && !selectedConnection.dbt_project_name}
      tabIndex={8}
    >
      <div className="invalid-feedback">
        A valid DBT Project Name name is required!
      </div>
    </ConnectionField>
  </>
);

export default DuckDBConnectionForm;
