import axios from "axios";
import * as CONSTANTS from "../utilities/Constants";
import { getHeaders } from "./common";

export const generateQuestions = async (questionInfo, type) => {
  try {
    const response = await axios.post(
      type === "fact"
        ? CONSTANTS.GENAI_FACT_QUESTION
        : CONSTANTS.GENAI_OTHER_QUESTION,
      questionInfo,
      {
        headers: getHeaders(),
      },
    );
    return response?.data || {};
  } catch (err) {
    return err;
  }
};

export const generateCode = async (questionInfo) => {
  try {
    const response = await axios.post(
      CONSTANTS.GENAI_FOLLOWUP_QUESTION,
      questionInfo,
      {
        headers: getHeaders(),
      },
    );
    return response.data;
  } catch (err) {
    return err;
  }
};


export const generateGenAIDashboards = async (questionInfo) => {
  try {
    const response = await axios.post(CONSTANTS.GENAI_DASHBOARD_FACT_QUESTION,
      questionInfo,
      {
        headers: getHeaders(),
      },
    );
    return response?.data || {};
  } catch (err) {
    return err;
  }
};


export const generateGenAIDashboardsOtherQuestions = async (questionInfo) => {
  try {
    const response = await axios.post(
      CONSTANTS.GENAI_DASHBOARD_OTHER_QUESTION,
      questionInfo,
      {
        headers: getHeaders(),
      },
    );
    return response?.data || {};
  } catch (err) {
    return err;
  }
};