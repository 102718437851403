import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import s from "./Sidebar.module.scss";
import LinksGroup from "./LinksGroup/LinksGroup";

import { changeActiveSidebarItem } from "../../actions/navigation";
import { logoutUser } from "../../actions/user";
import HomeIcon from "../Icons/SidebarIcons/HomeIcon";
import TablesIcon from "../Icons/SidebarIcons/TablesIcon";
import { withRouter } from "../../core/withRouter";
import LogsIcon from "../Icons/Global/LogsIcon";
import ReportsIcon from "../Icons/Global/ReportsIcon";

class Sidebar extends React.Component {
  static propTypes = {
    sidebarStatic: PropTypes.bool,
    sidebarOpened: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    activeItem: PropTypes.string,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
  };

  static defaultProps = {
    sidebarStatic: false,
    activeItem: "",
  };

  constructor(props) {
    super(props);

    this.doLogout = this.doLogout.bind(this);
  }

  componentDidMount() {
    this.element.addEventListener(
      "transitionend",
      () => {
        if (this.props.sidebarOpened) {
          this.element.classList.add(s.sidebarOpen);
        }
      },
      false,
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.sidebarOpened !== this.props.sidebarOpened) {
      if (nextProps.sidebarOpened) {
        this.element.style.height = `${this.element.scrollHeight}px`;
      } else {
        this.element.classList.remove(s.sidebarOpen);
        setTimeout(() => {
          this.element.style.height = "";
        }, 0);
      }
    }
  }

  doLogout() {
    this.props.dispatch(logoutUser());
  }

  render() {
    const userDetails = JSON.parse(
      localStorage.getItem("userInfo") || { permissions: {} },
    );
    const {
      admin,
      audits,
      connections,
      dashboards,
      etls,
      jobs,
      pipelines,
      genai,
      reports,
    } = userDetails?.permissions;

    return (
      <nav
        className={`${cx(s.root)}`}
        ref={(nav) => {
          this.element = nav;
        }}
      >
        <ul className={`${s.nav}`}>
          {dashboards && (
            <LinksGroup
              onActiveSidebarItemChange={(activeItem) =>
                this.props.dispatch(changeActiveSidebarItem(activeItem))
              }
              activeItem={this.props.activeItem}
              header="Home"
              isHeader
              iconName={<HomeIcon className={s.menuIcon} />}
              link="/app/main"
              index="main"
            />
          )}
          {connections && (
            <LinksGroup
              onActiveSidebarItemChange={(activeItem) =>
                this.props.dispatch(changeActiveSidebarItem(activeItem))
              }
              activeItem={this.props.activeItem}
              header="Connections"
              isHeader
              iconName={<i className={`fa fa-database mx-2 ${s.menuIcon}`}></i>}
              link="/app/connections"
              index="connections"
            />
          )}
          {pipelines && (
            <LinksGroup
              onActiveSidebarItemChange={(activeItem) =>
                this.props.dispatch(changeActiveSidebarItem(activeItem))
              }
              activeItem={this.props.activeItem}
              header="Pipelines"
              isHeader
              iconName={<i className={`fa fa-link mx-2 ${s.menuIcon}`}></i>}
              link="/app/pipelines?filterId=all"
              index="pipelines"
            />
          )}
          {etls && (
            <LinksGroup
              onActiveSidebarItemChange={(t) =>
                this.props.dispatch(changeActiveSidebarItem(t))
              }
              activeItem={this.props.activeItem}
              header="Data Warehouse"
              isHeader
              iconName={<TablesIcon className={s.menuIcon} />}
              link="/app/datawarehouse"
              index="datawarehouse"
            />
          )}
          {audits && (
            <LinksGroup
              onActiveSidebarItemChange={(activeItem) =>
                this.props.dispatch(changeActiveSidebarItem(activeItem))
              }
              activeItem={this.props.activeItem}
              header="Audits"
              isHeader
              iconName={<LogsIcon className={s.menuIcon} />}
              link="/app/audits"
              index="audits"
            />
          )}
          {jobs && (
            <LinksGroup
              onActiveSidebarItemChange={(activeItem) =>
                this.props.dispatch(changeActiveSidebarItem(activeItem))
              }
              activeItem={this.props.activeItem}
              header="Jobs"
              isHeader
              iconName={<i className={`fa fa-cogs mx-2 ${s.menuIcon}`}></i>}
              link="/app/jobs"
              index="jobs"
            />
          )}
          {reports && (
            <LinksGroup
              onActiveSidebarItemChange={(activeItem) =>
                this.props.dispatch(changeActiveSidebarItem(activeItem))
              }
              activeItem={this.props.activeItem}
              header="Reports"
              isHeader
              iconName={<ReportsIcon className={s.menuIcon} />}
              link="/app/reports"
              index="reports"
            />
          )}

          {admin && (
            <LinksGroup
              onActiveSidebarItemChange={(activeItem) =>
                this.props.dispatch(changeActiveSidebarItem(activeItem))
              }
              activeItem={this.props.activeItem}
              header="Administration"
              isHeader
              iconName={<i className="fa fa-linode fa-2x"></i>}
              link="/app/admin"
              index="admin"
            />
          )}

          {genai && (
            <LinksGroup
              onActiveSidebarItemChange={(activeItem) =>
                this.props.dispatch(changeActiveSidebarItem(activeItem))
              }
              activeItem={this.props.activeItem}
              header="Gen AI"
              isHeader
              iconName={
                <i className={`fa fa fa-android fa-2x ${s.menuIcon}`}></i>
              }
              link="/app/genai"
              index="genai"
            />
          )}
        </ul>
      </nav>
    );
  }
}

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    sidebarStatic: store.navigation.sidebarStatic,
    activeItem: store.navigation.activeItem,
  };
}

export default withRouter(connect(mapStateToProps)(Sidebar));
