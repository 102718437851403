import React, { useEffect, useRef, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, showLoader } from "../../actions/loader";
import {
  addConnection,
  deleteConnectionById,
  fetchConnections,
  fetchDimensionMetrics,
  fetchDimensions,
  fetchFiles,
  fetchLocalFiles,
  testConnection,
  updateConnection,
  v1Connection,
} from "../../api/connection";
import Widget from "../../components/Widget/Widget";
import s from "./Connections.module.scss";
import { confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import WizBIInput from "../../core/WizBIInput/WizBIInput";
import _databaseTypes from "../../assets/data/databaseType.json";
import { Dropdown } from "primereact/dropdown";
import { Divider } from "primereact/divider";
import WizBIDropDown from "../../core/WizBIDropDown/WizBIDropDown";
import { Password } from "primereact/password";
import { v4 as uuidv4 } from "uuid";
import { ListBox } from "primereact/listbox";
import { Checkbox } from "primereact/checkbox";
import classNames from "classnames";
import { VirtualScroller } from "primereact/virtualscroller";
import debouce from "lodash.debounce";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import DuckDBConnectionForm from "./DuckDBConnectionForm";
import IcebergConnectionForm from "./IcebergConnectionForm";

const Connections = () => {
  const toast = useRef(null);
  const dispatch = useDispatch();
  const [duckDBRadio, setDuckDBRadio] = useState("s3");
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [isAddSelected, setIsAddSelected] = useState(false);
  const [connectionLoading, setConnectionLoading] = useState(false);
  const [connectionStatus, setConnectionStatus] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [connectionsList, setConnectionsList] = useState([]);
  const [selectedConnection, setSelectedConnection] = useState(null);
  const [dataType, setDataType] = useState(null);
  const databaseTypes = _databaseTypes;
  const resetConnectionDetails = {
    db_conn_name: "",
    db_name: "",
    db_type: "",
    db_host: "",
    db_port: 0,
    db_username: "",
    db_password: "",
    sub_type: "",
    s3_access_key_id: "",
    s3_secret_access_key: "",
    s3_bucket: "",
    s3_bucket_path: "",
    s3_bucket_region: "",
    gdrive_client_id: "",
    gdrive_client_secret: "",
    gdrive_access_token: "",
    gdrive_refresh_token: "",
    gdrive_token_uri: "",
    gdrive_scopes: "",
    gdrive_path: "",
    gdrive_prefix: "",
    lfs_path: "",
    lfs_prefix: "",
    lfs_mount_point: "",
    ga_property_id: "",
    ga_auth_json: "",
    connection_ext: [],
  };
  const [filesList, setFilesList] = useState([]);
  const [dimensions, setDimensions] = useState([]);
  const [metrics, setMetrics] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedMetrics, setSelectedMetrics] = useState([]);
  const [selectedDimenson, setSelectedDimenson] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [metaLocationPath, setMetaLocationPath] = useState("");
  const searchRef = useRef("");
  const isLoading = useSelector((state) => state.loader.loaderVisibility);
  const onCheckBoxSelection = (e) => {
    let _selectedOptions = [...selectedOptions];

    if (e.checked) _selectedOptions.push(e.value);
    else _selectedOptions = _selectedOptions.filter((val) => val !== e.value);

    setSelectedOptions(_selectedOptions);
  };
  const accept = () => {
    dispatch(showLoader());
    deleteConnectionById(selectedConnection.id, (resp) => {
      dispatch(hideLoader());
      if (!!resp && (!!resp.detail || !!resp.message)) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: resp.detail || resp.message,
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "success",
          summary: "Confirmed",
          detail: "The connection has been successfully deleted",
          life: 3000,
        });
        getConnections();
      }
    });
  };

  const getConnections = () => {
    dispatch(showLoader());
    fetchConnections((resp) => {
      dispatch(hideLoader());
      if (!!resp && (!!resp.detail || !!resp.message)) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: resp.detail || resp.message,
          life: 3000,
        });
      } else {
        setConnectionsList(resp);
        // setSelectedConnection(null);
      }
    });
  };

  useEffect(() => {
    getConnections();
  }, []);

  const deleteConnection = () => {
    confirmDialog({
      message: "Do you want to delete this record?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept,
    });
  };

  const addConnectionInfo = () => {
    setIsAddSelected(true);
    setSelectedConnection(resetConnectionDetails);
  };

  const saveConnection = () => {
    if (
      dataType.source === "database" &&
      (!selectedConnection.db_conn_name ||
        !selectedConnection.db_name ||
        !selectedConnection.db_type ||
        !selectedConnection.db_host ||
        !selectedConnection.db_username ||
        !selectedConnection.db_password ||
        !selectedConnection.db_port)
    ) {
      return setSubmitted(true);
    }
    if (dataType.id === "harddisk") {
      if (
        dataType.source === "csv" &&
        (!selectedConnection.lfs_path || !selectedConnection.db_conn_name)
      ) {
        return setSubmitted(true);
      }
    } else if (dataType.id === "ICEBERG") {
      selectedConnection.db_type = "ICEBERG";
      if (
        (!selectedConnection.s3_access_key_id ||
          !selectedConnection.s3_secret_access_key ||
          !selectedConnection.s3_bucket ||
          !selectedConnection.s3_bucket_region ||
          !selectedConnection.db_conn_name || 
          !selectedConnection.iceberg_table ||
          !selectedConnection.iceberg_database )
      ) {
        return setSubmitted(true);
      }
    } else if(dataType.id === "DUCKDB"){
      selectedConnection.db_type = "DUCKDB";
      if(duckDBRadio === "s3" && (!selectedConnection.duckdb_database ||
        !selectedConnection.db_conn_name ||
        !selectedConnection.s3_access_key_id ||
        !selectedConnection.s3_secret_access_key ||
        !selectedConnection.s3_bucket ||
        !selectedConnection.s3_bucket_path ||
        !selectedConnection.s3_bucket_region ||
        !selectedConnection.duckdb_database||
        !selectedConnection.duckdb_lfs_path ||
        !selectedConnection.dbt_project_name )){
          return setSubmitted(true);
      } else if (
        (!selectedConnection.db_conn_name ||
          !selectedConnection.duckdb_database ||
          !selectedConnection.dbt_project_name ||
          !selectedConnection.duckdb_lfs_path )
      ) {
        return setSubmitted(true);
      }
    } else if (dataType.id === "ganalytics") {
      if (
        dataType.source === "csv" &&
        (!selectedConnection.ga_property_id || !selectedConnection.db_conn_name)
      ) {
        return setSubmitted(true);
      }
    } else {
      if (
        dataType.source === "csv" &&
        (!selectedConnection.s3_access_key_id ||
          !selectedConnection.s3_secret_access_key ||
          !selectedConnection.s3_bucket ||
          !selectedConnection.s3_bucket_region ||
          !selectedConnection.db_conn_name)
      ) {
        return setSubmitted(true);
      }
    }
    setSubmitted(false);
    if (isAddSelected) {
      dispatch(showLoader());
      let connectionDetails = { ...selectedConnection };
      if (
        connectionDetails.db_type.toLowerCase().includes("harddisk") ||
        connectionDetails.db_type.toLowerCase().includes("s3")
      ) {
        connectionDetails = {
          ...connectionDetails,
          db_type: dataType.name,
          connection_ext: selectedOptions.map((conExt) => {
            return {
              file_name: conExt.file_name,
              file_description: conExt.file_type,
            };
          }),
        };
      }
      if (connectionDetails.db_type.toLowerCase().includes("analytics")) {
        let parsedJson;
        try {
          parsedJson = JSON.parse(connectionDetails.ga_auth_json);
        } catch (error) {
          dispatch(hideLoader());
          return toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Invalid JSON from Auth JSON:" + error,
            life: 3000,
          });
        }

        connectionDetails = {
          ...connectionDetails,
          db_type: dataType.name,
          ga_auth_json: parsedJson,
          connection_ext: [
            {
              dimension: selectedDimenson.code,
              dimension_metric: selectedMetrics.map((metric) => metric.code),
            },
          ],
        };
      }
      addConnection(connectionDetails, (resp) => {
        dispatch(hideLoader());
        if (!!resp && (!!resp.detail || !!resp.message)) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: resp.detail || resp.message,
            life: 3000,
          });
        } else {
          toast.current.show({
            severity: "success",
            summary: "Confirmed",
            detail: "The connection has been added successfully",
            life: 3000,
          });
          setFilesList([]);
          getConnections();
        }
      });
    } else {
      let connectionDetails = { ...selectedConnection };
      if (connectionDetails.db_type.toLowerCase().includes("analytics")) {
        let parsedJson;
        try {
          parsedJson = JSON.parse(connectionDetails.ga_auth_json);
        } catch (error) {
          dispatch(hideLoader());
          return toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Invalid JSON from Auth JSON:" + error,
            life: 3000,
          });
        }
        connectionDetails = {
          ...connectionDetails,
          ga_auth_json: parsedJson,
          connection_ext: [
            {
              dimension: selectedDimenson.code,
              dimension_metric: selectedMetrics.map((metric) => metric.code),
            },
          ],
        };
      }
      dispatch(showLoader());
      updateConnection(connectionDetails.id, connectionDetails, (resp) => {
        dispatch(hideLoader());
        if (!!resp && (!!resp.detail || !!resp.message)) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: resp.detail || resp.message,
            life: 3000,
          });
        } else {
          toast.current.show({
            severity: "success",
            summary: "Confirmed",
            detail: "The connection has been updated successfully ",
            life: 3000,
          });
          setFilesList([]);
          getConnections();
        }
      });
    }
  };

  const connectionTesting = (id, v1Connect = false, payload) => {
    setConnectionLoading(true);
    if (v1Connect) {
      v1Connection(payload, (resp) => {
        setConnectionLoading(false);
        if (!!resp && (!!resp.detail || !!resp.message)) {
          setConnectionStatus(false);
        } else {
          setConnectionStatus(true);
          setMetaLocationPath(resp?.metadata_location || "");
        }
      });
    } else {
      testConnection(id, (resp) => {
        setConnectionLoading(false);
        if (!!resp && (!!resp.detail || !!resp.message)) {
          setConnectionStatus(false);
        } else {
          setConnectionStatus(true);
        }
      });
    }
  };

  const enableBackButton = isAddSelected && dataType;
  const header = (
    <>
      <div
        className={`d-flex align-items-center ${
          enableBackButton ? "justify-content-between" : "justify-content-end"
        }`}
      >
        {enableBackButton && (
          <div className="d-flex">
            <span
              role="button"
              onClick={() => {
                setDataType(null);
                setSelectedConnection(null);
              }}
            >
              <i className="fa fa-arrow-left mx-2"></i>back
            </span>
          </div>
        )}
        <span>
          <Button
            severity="info"
            className="mx-2 bg-wizBi p-2"
            onClick={() => addConnectionInfo()}
            disabled={isAddSelected || isRowSelected}
          >
            <i className="pi pi-plus mx-2"></i> Add
          </Button>
          <Button
            severity="danger"
            className="mx-2 p-2"
            onClick={() => deleteConnection(true)}
            disabled={!isRowSelected}
          >
            <i className="pi pi-trash mx-2"></i> Delete
          </Button>
          <Button
            severity="success"
            className="mx-2 p-2 wizBi-bg-success"
            onClick={() => saveConnection()}
            disabled={
              (!isRowSelected && !isAddSelected) ||
              !dataType ||
              !selectedConnection
            }
          >
            <i className="pi pi-check mx-2"></i> Submit
          </Button>
          <Button
            severity="danger"
            className="mx-2 p-2"
            onClick={() => reset()}
          >
            <i className="pi pi-times mx-2"></i> Reset
          </Button>
        </span>
      </div>
      <Divider />
    </>
  );

  const selectedDatabaseTemplate = (option, props) => {
    if (option) {
      return (
        <div className="d-flex align-items-center">
          <span
            className={`db-icon ${option.icon}-db`}
            style={{ width: "30px", height: "30px" }}
          ></span>
          <div className="mx-3">{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const databaseOptionTemplate = (option) => {
    return (
      <div className="d-flex">
        <div className="d-flex align-items-center">
          <span
            className={`db-icon ${option.icon}-db`}
            style={{ width: "30px", height: "30px" }}
          ></span>
          <div className="mx-3">{option.name}</div>
        </div>
      </div>
    );
  };

  const itemTemplate = (item, options) => {
    const className = classNames("flex align-items-center p-2", {
      "surface-hover": options.odd,
    });

    return (
      <div
        className={className}
        style={{ height: options.props.itemSize + "px" }}
      >
        <div key={item} className="d-flex align-items-center">
          {isAddSelected ? (
            <Checkbox
              inputId={item.file_name}
              name="item"
              value={item}
              onChange={onCheckBoxSelection}
              checked={selectedOptions.some(
                (category) => item.file_name === category.file_name
              )}
            />
          ) : (
            <span
              className="db-icon CSV-db"
              style={{ width: "30px", height: "30px" }}
            ></span>
          )}

          <label htmlFor={item.file_name} className="mx-2">
            {item.file_name}
          </label>
        </div>
      </div>
    );
  };

  const setDTtype = (conn) => {
    let dTYpe = "s3";
    if (conn.db_type) {
      if (conn.db_type.toLowerCase().includes("local")) {
        dTYpe = "harddisk";
        return dTYpe;
      }
      if (conn.db_type.toLowerCase().includes("analytics")) {
        dTYpe = "ganalytics";
        return dTYpe;
      }
      if (conn.db_type.toLowerCase().includes("analytics")) {
        dTYpe = "ganalytics";
        return dTYpe;
      }
      if (conn.db_type.toLowerCase().includes("DUCKDB")) {
        dTYpe = "DUCKDB";
        return dTYpe;
      }
      if (conn.db_type.toLowerCase().includes("ICEBERG")) {
        dTYpe = "ICEBERG";
        return dTYpe;
      }
      if (!conn.db_type.toLowerCase().includes("amazon")) {
        dTYpe = conn.db_type;
      }
    }
    return dTYpe;
  };

  const reset = () => {
    setIsAddSelected(false);
    setIsRowSelected(false);
    setDataType(null);
    setSubmitted(false);
    setFilesList([]);
    setDimensions({});
    setMetrics([]);
    setSelectedOptions();
  };

  const metricsTemplate = (option) => {
    const isChecked = selectedMetrics.includes(option);
    const handleMetricsChange = (e) => {
      e.preventDefault();
      const { checked } = e.target;
      const _selectedMetrics = selectedMetrics.filter(
        (metric) => metric.code !== option.code
      );
      if (checked) {
        setSelectedMetrics([...selectedMetrics, option]);
      } else {
        setSelectedMetrics([..._selectedMetrics]);
      }
    };

    return (
      <div className="d-flex align-items-center">
        <Checkbox
          inputId={`metrics_${option.code}`}
          value={option.code}
          onChange={handleMetricsChange}
          checked={isChecked}
        />
        <label htmlFor={`metrics_${option.code}`} className="mx-2">
          {option.name}
        </label>
      </div>
    );
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  const debouncedResults = useMemo(() => {
    return debouce(handleSearch, 600);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  let filterBySearch = connectionsList;
  if (searchTerm !== "") {
    filterBySearch = connectionsList.filter((item) => {
      const value = searchTerm.toLowerCase();
      console.log(item);
      if (
        (item.db_conn_name &&
          item.db_conn_name.toString().toLowerCase().includes(value)) ||
        (item.db_type && item?.db_type.toLowerCase().includes(value))
      ) {
        return item;
      }
    });
  }

  const dimensionsTemplate = (option) => {
    const isChecked = selectedDimenson
      ? selectedDimenson.code === option.code
      : false;
    const handleDimensionChange = (e) => {
      e.preventDefault();
      const collection = metrics.filter((metric) => {
        return metric.dimension_id === option.code;
      });
      if (!collection.length) {
        dispatch(showLoader());
        getDimensionsMetrics(option.code, selectedConnection);
      }
      setSelectedDimenson(option);
    };

    return (
      <div className="d-flex align-items-center">
        <RadioButton
          name={`dimension${option.code}`}
          value={option.code}
          onChange={handleDimensionChange}
          checked={isChecked}
        />
        <label htmlFor={`dimension${option.id}`} className="mx-2">
          {option.name}
        </label>
      </div>
    );
  };

  const getDimensionsMetrics = (
    id,
    { connection_ext, ga_property_id, ga_auth_json }
  ) => {
    setTimeout(() => {
      setMetrics([]);
    }, 0);
    if (!id) {
      return;
    }
    fetchDimensionMetrics(
      {
        ga_property_id: ga_property_id,
        ga_auth_json:
          typeof ga_auth_json === "string"
            ? JSON.parse(ga_auth_json)
            : ga_auth_json,
      },
      id,
      (resp) => {
        dispatch(hideLoader());
        if (!!resp && (!!resp.detail || !!resp.message)) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: resp.detail || resp.message,
            life: 3000,
          });
        } else {
          const allData = [...resp];
          const existingIds = allData.map((data) => data.id);
          metrics.forEach((comb) => {
            if (!existingIds.includes(comb.id)) {
              allData.push(comb);
            }
          });

          if (
            !isAddSelected &&
            connection_ext &&
            id === connection_ext[0].dimension
          ) {
            const _metrics = resp.filter((metric) =>
              connection_ext[0].dimension_metric.includes(metric.code)
            );
            setSelectedMetrics(_metrics);
          } else {
            setSelectedMetrics([]);
          }
          setMetrics(allData);
        }
      }
    );
  };
  const getDimensions = ({ connection_ext, ga_property_id, ga_auth_json }) => {
    setTimeout(() => {
      setMetrics([]);
      setSelectedDimenson({});
    }, 0);
    fetchDimensions(
      {
        ga_property_id: ga_property_id,
        ga_auth_json:
          typeof ga_auth_json === "string"
            ? JSON.parse(ga_auth_json)
            : ga_auth_json,
      },
      (resp) => {
        dispatch(hideLoader());
        if (!!resp && (!!resp.detail || !!resp.message)) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: resp.detail || resp.message,
            life: 3000,
          });
        } else {
          setDimensions(resp);
          if (!isAddSelected) {
            const _dimension = resp.find(
              (dim) => dim.code === connection_ext[0].dimension
            );
            setSelectedDimenson(_dimension);
            dispatch(showLoader());
            getDimensionsMetrics(_dimension.code, {
              connection_ext,
              ga_property_id,
              ga_auth_json,
            });
          }
        }
      }
    );
  };

  return (
    <>
      <div className={`row ${s.root}`}>
        <div className={`col-md-4 col-lg-4 p-0 px-3 m-0 ${s.wrapper}`}>
          <Widget
            title={
              <>
                <h5 className="py-0 d-flex align-items-center justify-content-between pb-2">
                  Connections
                  <i
                    className="fa fa-refresh"
                    onClick={() => {
                      getConnections();
                      reset();
                    }}
                    role="button"
                  ></i>
                </h5>
                <Divider />
              </>
            }
            className={`mb-0 background-white pb-3`}
            bodyClass={`m-0 p-0 ${s.widgetBodyClass}`}
          >
            {" "}
            <div
              className={`w-100 overflow-auto ${
                !!filterBySearch.length ? "h-100" : ""
              } px-3`}
            >
              <div className="p-input-icon-left w-100">
                <i className="pi pi-search" />
                <InputText
                  placeholder="Search for connection name, db type"
                  style={{ width: "100%", height: "30px" }}
                  onChange={debouncedResults}
                  ref={searchRef}
                />
              </div>
              {!!filterBySearch.length ? (
                filterBySearch.map((conn) => {
                  return (
                    <div>
                      <Widget
                        className={`${s.connectionItem} my-2 p-0 ${
                          conn === selectedConnection ? "active-item" : ""
                        }`}
                        key={uuidv4()}
                      >
                        <div
                          className={`w-100 h-100 m-2 p-2`}
                          role="button"
                          onClick={() => {
                            reset();
                            if (conn.ga_auth_json) {
                              setSelectedConnection({
                                ...conn,
                                ga_auth_json: JSON.stringify(
                                  conn.ga_auth_json,
                                  null,
                                  2
                                ),
                              });
                            } else {
                              setSelectedConnection({ ...conn });
                            }
                            setIsRowSelected(true);
                            setIsAddSelected(false);
                            setConnectionStatus(null);
                            setDataType(
                              databaseTypes.find(
                                (dt) => dt.id === setDTtype(conn)
                              )
                            );
                            const isGoogleAnalytics = conn.db_type
                              .toLowerCase()
                              .replace(/ +/g, "")
                              .includes("googleanalytics");
                            if (isGoogleAnalytics) {
                              dispatch(showLoader());
                              getDimensions(conn);
                            }
                          }}
                        >
                          <div className="d-flex align-items-center text-truncate">
                            <span
                              className={`db-icon ${setDTtype(conn)}-db`}
                              style={{ width: "60px", height: "60px" }}
                            ></span>
                            <div className="flex-grow-1">
                              <h5 className="mx-2 text-wizBi text-capitalize p-0 my-0">
                                {conn.db_conn_name}
                              </h5>
                              {conn.db_type && !conn.db_type.toLowerCase().includes("iceberg") && !conn.db_type.toLowerCase().includes("dbtype")  &&
                                !conn.db_type
                                  .toLowerCase()
                                  .includes("amazon") &&
                                !conn.db_type.toLowerCase().includes("local") &&
                                !conn.db_type
                                  .toLowerCase()
                                  .includes("analytics") && (
                                  <>
                                    {" "}
                                    <div className="row m-0 p-0 text-truncate">
                                      <div className="col text-black px-1 text-truncate">
                                        <small className="text-black text-capitalize p-0 my-0">
                                          <label className="mx-2">
                                            Name :{" "}
                                          </label>
                                          {conn.db_name}
                                        </small>
                                      </div>
                                    </div>
                                    <div className="row m-0 p-0 text-truncate">
                                      <div className="col text-black px-1 text-truncate">
                                        <small className="d-flex">
                                          <label className="mx-2">
                                            Type :{" "}
                                          </label>
                                          {conn.db_type}
                                        </small>
                                        <small className="d-flex">
                                          <label className="mx-2">
                                            User :{" "}
                                          </label>
                                          {conn.db_username}
                                        </small>
                                      </div>
                                      <div className="col text-black px-1 text-truncate">
                                        <small
                                          className="d-flex text-truncate"
                                          style={{ width: "100px" }}
                                        >
                                          <label className="mx-2">
                                            {" "}
                                            Host :{" "}
                                          </label>
                                          {conn.db_host}
                                        </small>
                                        <small className="d-flex">
                                          <label className="mx-2">
                                            {" "}
                                            Port :{" "}
                                          </label>
                                          {conn.db_port}
                                        </small>
                                      </div>
                                    </div>
                                  </>
                                )}
                              {(!conn.db_type ||
                                (conn.db_type &&
                                  conn.db_type
                                    .toLowerCase()
                                    .includes("amazon"))) && (
                                <div className="row m-0 p-0 text-truncate">
                                  <div className="col text-black px-1 text-truncate">
                                    <small className="d-flex">
                                      <label className="mx-2">Bucket : </label>
                                      {conn.s3_bucket}
                                    </small>
                                    <small className="d-flex">
                                      <label className="mx-2">
                                        {" "}
                                        Bucket Region :{" "}
                                      </label>
                                      {conn.s3_bucket_region}
                                    </small>
                                  </div>
                                  {/* <div className="col text-black px-1 text-truncate">
                                                            <small className="d-flex"><label className="mx-2"> Bucket Region : </label>{conn.s3_bucket_region}</small>
                                                        </div> */}
                                </div>
                              )}

                              {conn.db_type &&
                                (conn.db_type
                                  .toLowerCase()
                                  .includes("local") || conn.db_type
                                  .toLowerCase()
                                  .includes("dbtype") || conn.db_type
                                  .toLowerCase()
                                  .includes("iceberg")) && (
                                  <div className="row m-0 p-0 text-truncate">
                                    <div className="col text-black px-1 text-truncate">
                                      <small className="d-flex">
                                        <label className="mx-2">Path : </label>
                                        {(conn.db_type
                                  .toLowerCase()
                                  .includes("dbtype") || conn.db_type
                                  .toLowerCase()
                                  .includes("iceberg")) ? (conn.duckdb_lfs_path || conn.s3_bucket_path) : conn.lfs_path}
                                      </small>
                                      {conn.db_type
                                  .toLowerCase()
                                  .includes("local") ? <small className="d-flex">
                                        <label className="mx-2">
                                          {" "}
                                          Prefix :{" "}
                                        </label>
                                        {conn.lfs_prefix}
                                      </small> : ""}
                                    </div>
                                    {/* <div className="col text-black px-1 text-truncate">
                                                            <small className="d-flex"><label className="mx-2"> Bucket Region : </label>{conn.s3_bucket_region}</small>
                                                        </div> */}
                                  </div>
                                )}

                              {conn.db_type &&
                                conn.db_type
                                  .toLowerCase()
                                  .includes("analytics") && (
                                  <div className="row m-0 p-0 text-truncate">
                                    <div className="col text-black px-1 text-truncate">
                                      <small className="d-flex">
                                        <label className="mx-2">
                                          Property Id :{" "}
                                        </label>
                                        {conn.ga_property_id}
                                      </small>
                                    </div>
                                    {/* <div className="col text-black px-1 text-truncate">
                                                            <small className="d-flex"><label className="mx-2"> Bucket Region : </label>{conn.s3_bucket_region}</small>
                                                        </div> */}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </Widget>
                    </div>
                  );
                })
              ) : isLoading ? (
                <li className="list-item d-flex justify-content-center mt-5">
                  <h5>Loading ...</h5>
                </li>
              ) : (
                <li className="list-item d-flex justify-content-center mt-5 flex-column align-items-center">
                  <span>
                    <i className="fa-solid fa-print-magnifying-glass"></i>
                  </span>
                  <h5>No data available</h5>
                  <span>
                    Sorry, we couldn't find any results for the search{" "}
                    {searchTerm}
                  </span>
                </li>
              )}
            </div>
          </Widget>
        </div>
        <div className={`col-md-8 col-lg-8 px-3 m-0 ${s.wrapper}`}>
          <Widget
            title={header}
            className={`mb-0 background-white pb-3`}
            bodyClass={`m-0 pt-0 ${s.widgetBodyClass}`}
          >
            {isAddSelected && !dataType && (
              <div className="row">
                {databaseTypes.map((dType) => {
                  return (
                    <div
                      style={{ width: "250px" }}
                      className="py-0 px-0 mb-2"
                      key={uuidv4()}
                    >
                      <Widget
                        bodyClass="p-0 py-2 mx-2"
                        className={`my-1 mx-3`}
                        role="button"
                        onClick={(evt) => {
                          evt.preventDefault();
                          setDataType(dType);
                          setSelectedConnection({
                            ...selectedConnection,
                            db_type: dType.id,
                          });
                        }}
                      >
                        <div
                          className={`db-icon ${dType.icon}-db`}
                          style={{ width: "30px", height: "30px" }}
                        ></div>
                        <h6>
                          {dType.name}
                          <small
                            className="d-block"
                            style={{ fontSize: "10px" }}
                          >
                            {dType.source}
                          </small>
                        </h6>
                      </Widget>
                    </div>
                  );
                })}
              </div>
            )}

            {!!selectedConnection && dataType ? (
              <div className={`w-100`}>
                <>
                  {dataType.source === "database" && (
                    <div className="row">
                      <div className="col col-md-6">
                        <div className="form-group mb-2">
                          <WizBIInput
                            labelName="Connection Name"
                            className={`${
                              submitted && !selectedConnection.db_conn_name
                                ? "is-invalid"
                                : ""
                            }`}
                            controls={{
                              value: selectedConnection.db_conn_name,
                              onChange: (e) => {
                                setSelectedConnection({
                                  ...selectedConnection,
                                  db_conn_name: e.target.value,
                                });
                              },
                              id: "db_conn_name",
                              tabindex: 1,
                            }}
                          >
                            {" "}
                            <div className="invalid-feedback">
                              A valid connection name is required!
                            </div>{" "}
                          </WizBIInput>
                        </div>
                        <div className="form-group mb-2">
                          <WizBIInput
                            labelName="Database Name"
                            className={`${
                              submitted && !selectedConnection.db_name
                                ? "is-invalid"
                                : ""
                            }`}
                            controls={{
                              value: selectedConnection.db_name,
                              onChange: (e) => {
                                setSelectedConnection({
                                  ...selectedConnection,
                                  db_name: e.target.value,
                                });
                              },
                              id: "db_conn_name",
                              tabindex: 3,
                            }}
                          >
                            {" "}
                            <div className="invalid-feedback">
                              A valid database name is required!
                            </div>{" "}
                          </WizBIInput>
                        </div>
                        <div className="form-group mb-2">
                          <WizBIInput
                            labelName="Database User"
                            className={`${
                              submitted && !selectedConnection.db_username
                                ? "is-invalid"
                                : ""
                            }`}
                            controls={{
                              value: selectedConnection.db_username,
                              onChange: (e) => {
                                setSelectedConnection({
                                  ...selectedConnection,
                                  db_username: e.target.value,
                                });
                              },
                              id: "db_username",
                              tabindex: 5,
                            }}
                          >
                            {" "}
                            <div className="invalid-feedback">
                              A valid user name is required!
                            </div>{" "}
                          </WizBIInput>
                        </div>

                        <div className="form-group mb-2">
                          <WizBIInput
                            labelName="Database Port"
                            className={`${
                              submitted && !selectedConnection.db_port
                                ? "is-invalid"
                                : ""
                            }`}
                            controls={{
                              value: selectedConnection.db_port,
                              onChange: (e) => {
                                setSelectedConnection({
                                  ...selectedConnection,
                                  db_port: e.target.value,
                                });
                              },
                              id: "db_port",
                              type: "number",
                              tabindex: 7,
                            }}
                          >
                            {" "}
                            <div className="invalid-feedback">
                              A valid port is required!
                            </div>{" "}
                          </WizBIInput>{" "}
                        </div>
                      </div>
                      <div className="col col-md-6">
                        <div className="form-group mb-2">
                          <WizBIDropDown
                            labelName="Database Type"
                            className={`${
                              submitted && !selectedConnection.db_type
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            <Dropdown
                              filter
                              value={selectedConnection.db_type}
                              style={{ height: "35px" }}
                              panelClassName="text-black"
                              className={`p-0 m-0 custom-conn-drop w-100 d-flex form-control active ${
                                submitted && !selectedConnection.db_type
                                  ? " border border-danger"
                                  : ""
                              }`}
                              valueTemplate={selectedDatabaseTemplate}
                              itemTemplate={databaseOptionTemplate}
                              options={databaseTypes}
                              optionLabel="name"
                              tabIndex={2}
                              disabled="true"
                              optionValue="id"
                              placeholder="Select a DB Connection"
                            />
                            <div
                              className={`invalid-feedback${
                                submitted && !selectedConnection.db_type
                                  ? " d-block"
                                  : ""
                              }`}
                            >
                              Please select a database type!
                            </div>
                          </WizBIDropDown>
                        </div>

                        <div className="form-group mb-2">
                          <WizBIInput
                            labelName="Database Host"
                            className={`${
                              submitted && !selectedConnection.db_host
                                ? "is-invalid"
                                : ""
                            }`}
                            controls={{
                              value: selectedConnection.db_host,
                              onChange: (e) => {
                                setSelectedConnection({
                                  ...selectedConnection,
                                  db_host: e.target.value,
                                });
                              },
                              id: "db_host",
                              tabindex: 4,
                            }}
                          >
                            {" "}
                            <div className="invalid-feedback">
                              A valid databse host is required!
                            </div>{" "}
                          </WizBIInput>{" "}
                        </div>
                        <div className="form-group mb-2">
                          {/* <WizBIInput labelName='Database Password'
                                            className={`${(submitted && !selectedConnection.db_password) ? 'is-invalid' : ''}`}
                                            controls={
                                                {
                                                    value: selectedConnection.db_password,
                                                    onChange: (e) => { setSelectedConnection({ ...selectedConnection, db_password: e.target.value }) },
                                                    id: 'db_password',
                                                    type: 'password',
                                                    style: {
                                                        height: '40px'
                                                    }
                                                }
                                            }> <div className='invalid-feedback'>A valid password is required!</div> </WizBIInput> */}

                          <div className="form-group mb-2">
                            <WizBIDropDown labelName="Database Password">
                              <Password
                                autocomplete="off"
                                tabIndex={6}
                                value={selectedConnection.db_password}
                                style={{ height: "35px" }}
                                onChange={(e) => {
                                  setSelectedConnection({
                                    ...selectedConnection,
                                    db_password: e.target.value,
                                  });
                                }}
                                toggleMask
                                className="p-0 m-0 w-100 form-control d-flex active align-items-center"
                                inputClassName="form-control w-100 border-0 text-black"
                                feedback={false}
                              />
                            </WizBIDropDown>
                          </div>
                        </div>
                      </div>

                      {selectedConnection.id && (
                        <>
                          {" "}
                          <div className="col col-md-8">
                            {connectionStatus === true && (
                              <div className="alert alert-success">
                                <strong>Success!</strong> Connection Passed
                              </div>
                            )}

                            {connectionStatus === false && (
                              <div className="alert alert-danger">
                                <strong>Failed!</strong> Connection Failed!
                                please check database connection properties
                              </div>
                            )}
                          </div>
                          <div className="col col-md-4">
                            <button
                              className="btn bg-wizBi text-white pull-right"
                              disabled={connectionLoading}
                              onClick={() => {
                                connectionTesting(selectedConnection.id);
                              }}
                            >
                              {connectionLoading && (
                                <span
                                  className="spinner-border spinner-border-sm mx-2"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              )}
                              Test Connection
                            </button>
                          </div>{" "}
                        </>
                      )}
                    </div>
                  )}

                  {dataType.id === "s3" && (
                    <>
                      <div className="row">
                        <div className="col col-md-6">
                          <div className="form-group mb-2">
                            <WizBIInput
                              labelName="Connection Name"
                              className={`${
                                submitted && !selectedConnection.db_conn_name
                                  ? "is-invalid"
                                  : ""
                              }`}
                              controls={{
                                value: selectedConnection.db_conn_name,
                                onChange: (e) => {
                                  setSelectedConnection({
                                    ...selectedConnection,
                                    db_conn_name: e.target.value,
                                  });
                                },
                                id: "db_conn_name",
                                tabindex: 1,
                              }}
                            >
                              {" "}
                              <div className="invalid-feedback">
                                A valid connection name is required!
                              </div>{" "}
                            </WizBIInput>
                          </div>
                          {isAddSelected && (
                            <div className="form-group mb-2">
                              <WizBIInput
                                labelName="Secret Acess Key"
                                className={`${
                                  submitted &&
                                  !selectedConnection.s3_secret_access_key
                                    ? "is-invalid"
                                    : ""
                                }`}
                                controls={{
                                  value:
                                    selectedConnection.s3_secret_access_key,
                                  onChange: (e) => {
                                    setSelectedConnection({
                                      ...selectedConnection,
                                      s3_secret_access_key: e.target.value,
                                    });
                                  },
                                  id: "s3_secret_access_key",
                                  tabindex: 3,
                                }}
                              >
                                {" "}
                                <div className="invalid-feedback">
                                  A valid Secret Acess Key is required!
                                </div>{" "}
                              </WizBIInput>
                            </div>
                          )}
                          <div className="form-group mb-2">
                            <WizBIInput
                              labelName="Path/Folder"
                              // className={`${(submitted && !selectedConnection.s3_bucket_path) ? 'is-invalid' : ''}`}
                              controls={{
                                value: selectedConnection.s3_bucket_path,
                                onChange: (e) => {
                                  setSelectedConnection({
                                    ...selectedConnection,
                                    s3_bucket_path: e.target.value,
                                  });
                                },
                                id: "s3_bucket_path",
                                tabindex: 5,
                              }}
                            >
                              {/* <div className='invalid-feedback'>A valid user name is required!</div> */}
                            </WizBIInput>
                          </div>
                        </div>
                        <div className="col col-md-6">
                          {isAddSelected && (
                            <div className="form-group mb-2">
                              <WizBIInput
                                labelName="Access Key ID"
                                className={`${
                                  submitted &&
                                  !selectedConnection.s3_access_key_id
                                    ? "is-invalid"
                                    : ""
                                }`}
                                controls={{
                                  value: selectedConnection.s3_access_key_id,
                                  onChange: (e) => {
                                    setSelectedConnection({
                                      ...selectedConnection,
                                      s3_access_key_id: e.target.value,
                                    });
                                  },
                                  id: "s3_access_key_id",
                                  tabindex: 1,
                                }}
                              >
                                {" "}
                                <div className="invalid-feedback">
                                  A valid access key id is required!
                                </div>{" "}
                              </WizBIInput>
                            </div>
                          )}
                          <div className="form-group mb-2">
                            <WizBIInput
                              labelName="Bucket"
                              className={`${
                                submitted && !selectedConnection.s3_bucket
                                  ? "is-invalid"
                                  : ""
                              }`}
                              controls={{
                                value: selectedConnection.s3_bucket,
                                onChange: (e) => {
                                  setSelectedConnection({
                                    ...selectedConnection,
                                    s3_bucket: e.target.value,
                                  });
                                },
                                id: "s3_bucket",
                                tabindex: 3,
                              }}
                            >
                              {" "}
                              <div className="invalid-feedback">
                                A valid bucket is required!
                              </div>{" "}
                            </WizBIInput>
                          </div>
                          <div className="form-group mb-2">
                            <WizBIInput
                              labelName="Bucket Region"
                              className={`${
                                submitted &&
                                !selectedConnection.s3_bucket_region
                                  ? "is-invalid"
                                  : ""
                              }`}
                              controls={{
                                value: selectedConnection.s3_bucket_region,
                                onChange: (e) => {
                                  setSelectedConnection({
                                    ...selectedConnection,
                                    s3_bucket_region: e.target.value,
                                  });
                                },
                                id: "s3_bucket_region",
                                tabindex: 5,
                              }}
                            >
                              {" "}
                              <div className="invalid-feedback">
                                A valid bucket region is required!
                              </div>{" "}
                            </WizBIInput>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {isAddSelected && (
                          <div className="col-md-2 col-lg-2">
                            <button
                              className="btn bg-wizBi text-white"
                              style={{ width: "100px" }}
                              onClick={() => {
                                if (
                                  !selectedConnection.s3_access_key_id ||
                                  !selectedConnection.s3_secret_access_key ||
                                  !selectedConnection.s3_bucket ||
                                  !selectedConnection.s3_bucket_region
                                ) {
                                  return setSubmitted(true);
                                }
                                setSubmitted(false);
                                dispatch(showLoader());
                                fetchFiles(
                                  {
                                    s3_access_key_id:
                                      selectedConnection.s3_access_key_id,
                                    s3_secret_access_key:
                                      selectedConnection.s3_secret_access_key,
                                    s3_bucket: selectedConnection.s3_bucket,
                                    s3_bucket_path:
                                      selectedConnection.s3_bucket_path,
                                    s3_bucket_region:
                                      selectedConnection.s3_bucket_region,
                                  },
                                  (resp) => {
                                    dispatch(hideLoader());
                                    if (
                                      !!resp &&
                                      (!!resp.detail || !!resp.message)
                                    ) {
                                      toast.current.show({
                                        severity: "error",
                                        summary: "Error",
                                        detail: resp.detail || resp.message,
                                        life: 3000,
                                      });
                                    } else {
                                      setFilesList(resp);
                                    }
                                  }
                                );
                              }}
                            >
                              Get Files
                            </button>
                          </div>
                        )}
                        {isAddSelected && !!filesList.length && (
                          <div className="col-md-7 col-lg-7">
                            <h6 className="mx-3 text-wizBi">CSV files list</h6>
                            <VirtualScroller
                              items={filesList}
                              itemSize={50}
                              itemTemplate={itemTemplate}
                              className="surface-border border-round"
                              style={{ height: "150px" }}
                            />
                          </div>
                        )}

                        {!isAddSelected &&
                          !!selectedConnection.connection_ext?.length && (
                            <div className="col-md-5 col-lg-5">
                              <h6 className="mx-3 text-wizBi">
                                CSV files list
                              </h6>
                              <VirtualScroller
                                items={selectedConnection.connection_ext}
                                itemSize={50}
                                itemTemplate={itemTemplate}
                                className="surface-border border-round"
                                style={{ height: "150px" }}
                              />
                            </div>
                          )}
                      </div>
                    </>
                  )}

                  {dataType.id === "gdrive" && (
                    <div className="row">
                      <div className="col-md-12 col-lg-12">
                        <div className="form-group">
                          <WizBIInput
                            labelName="Connection Name"
                            className={`${
                              submitted && !selectedConnection.db_conn_name
                                ? "is-invalid"
                                : ""
                            }`}
                            controls={{
                              value: selectedConnection.db_conn_name,
                              onChange: (e) => {
                                setSelectedConnection({
                                  ...selectedConnection,
                                  db_conn_name: e.target.value,
                                });
                              },
                              id: "db_conn_name",
                              tabindex: 1,
                            }}
                          >
                            {" "}
                            <div className="invalid-feedback">
                              A valid connection name is required!
                            </div>{" "}
                          </WizBIInput>
                        </div>
                      </div>
                      <div className="col col-md-6">
                        <div className="form-group mb-2">
                          <WizBIInput
                            labelName="Client ID"
                            className={`${
                              submitted && !selectedConnection.gdrive_client_id
                                ? "is-invalid"
                                : ""
                            }`}
                            controls={{
                              value: selectedConnection.gdrive_client_id,
                              onChange: (e) => {
                                setSelectedConnection({
                                  ...selectedConnection,
                                  gdrive_client_id: e.target.value,
                                });
                              },
                              id: "gdrive_client_id",
                              tabindex: 1,
                            }}
                          >
                            {" "}
                            <div className="invalid-feedback">
                              A valid client ID is required!
                            </div>{" "}
                          </WizBIInput>
                        </div>
                        <div className="form-group mb-2">
                          <WizBIInput
                            labelName="Access Token"
                            className={`${
                              submitted &&
                              !selectedConnection.gdrive_access_token
                                ? "is-invalid"
                                : ""
                            }`}
                            controls={{
                              value: selectedConnection.gdrive_access_token,
                              onChange: (e) => {
                                setSelectedConnection({
                                  ...selectedConnection,
                                  gdrive_access_token: e.target.value,
                                });
                              },
                              id: "gdrive_access_token",
                              tabindex: 5,
                            }}
                          >
                            {" "}
                            <div className="invalid-feedback">
                              A valid access token is required!
                            </div>{" "}
                          </WizBIInput>
                        </div>
                        <div className="form-group mb-2">
                          <WizBIInput
                            labelName="Token URI"
                            className={`${
                              submitted && !selectedConnection.gdrive_token_uri
                                ? "is-invalid"
                                : ""
                            }`}
                            controls={{
                              value: selectedConnection.gdrive_token_uri,
                              onChange: (e) => {
                                setSelectedConnection({
                                  ...selectedConnection,
                                  gdrive_token_uri: e.target.value,
                                });
                              },
                              id: "gdrive_token_uri",
                              tabindex: 3,
                            }}
                          >
                            {" "}
                            <div className="invalid-feedback">
                              A valid token URI is required!
                            </div>{" "}
                          </WizBIInput>
                        </div>
                        <div className="form-group mb-2">
                          <WizBIInput
                            labelName="Folder/Path"
                            className={`${
                              submitted && !selectedConnection.gdrive_path
                                ? "is-invalid"
                                : ""
                            }`}
                            controls={{
                              value: selectedConnection.gdrive_path,
                              onChange: (e) => {
                                setSelectedConnection({
                                  ...selectedConnection,
                                  gdrive_path: e.target.value,
                                });
                              },
                              id: "gdrive_path",
                              tabindex: 5,
                            }}
                          >
                            {" "}
                            <div className="invalid-feedback">
                              A valid folder/path is required!
                            </div>{" "}
                          </WizBIInput>
                        </div>
                      </div>
                      <div className="col col-md-6">
                        <div className="form-group mb-2">
                          <WizBIInput
                            labelName="Client Secret"
                            className={`${
                              submitted &&
                              !selectedConnection.gdrive_client_secret
                                ? "is-invalid"
                                : ""
                            }`}
                            controls={{
                              value: selectedConnection.gdrive_client_secret,
                              onChange: (e) => {
                                setSelectedConnection({
                                  ...selectedConnection,
                                  gdrive_client_secret: e.target.value,
                                });
                              },
                              id: "gdrive_client_secret",
                              tabindex: 1,
                            }}
                          >
                            {" "}
                            <div className="invalid-feedback">
                              A valid client secret is required!
                            </div>{" "}
                          </WizBIInput>
                        </div>
                        <div className="form-group mb-2">
                          <WizBIInput
                            labelName="Refresh token"
                            className={`${
                              submitted &&
                              !selectedConnection.gdrive_refresh_token
                                ? "is-invalid"
                                : ""
                            }`}
                            controls={{
                              value: selectedConnection.gdrive_refresh_token,
                              onChange: (e) => {
                                setSelectedConnection({
                                  ...selectedConnection,
                                  gdrive_refresh_token: e.target.value,
                                });
                              },
                              id: "gdrive_refresh_token",
                              tabindex: 3,
                            }}
                          >
                            {" "}
                            <div className="invalid-feedback">
                              A valid refresh token is required!
                            </div>{" "}
                          </WizBIInput>
                        </div>
                        <div className="form-group mb-2">
                          <WizBIInput
                            labelName="Scopes"
                            className={`${
                              submitted && !selectedConnection.gdrive_scopes
                                ? "is-invalid"
                                : ""
                            }`}
                            controls={{
                              value: selectedConnection.gdrive_scopes,
                              onChange: (e) => {
                                setSelectedConnection({
                                  ...selectedConnection,
                                  gdrive_scopes: e.target.value,
                                });
                              },
                              id: "gdrive_scopes",
                              tabindex: 5,
                            }}
                          >
                            {" "}
                            <div className="invalid-feedback">
                              A valid scopes region is required!
                            </div>{" "}
                          </WizBIInput>
                        </div>
                        <div className="form-group mb-2">
                          <WizBIInput
                            labelName="Prefix"
                            className={`${
                              submitted && !selectedConnection.gdrive_prefix
                                ? "is-invalid"
                                : ""
                            }`}
                            controls={{
                              value: selectedConnection.gdrive_prefix,
                              onChange: (e) => {
                                setSelectedConnection({
                                  ...selectedConnection,
                                  gdrive_prefix: e.target.value,
                                });
                              },
                              id: "gdrive_prefix",
                              tabindex: 5,
                            }}
                          >
                            {" "}
                            <div className="invalid-feedback">
                              A valid prefix is required!
                            </div>{" "}
                          </WizBIInput>
                        </div>
                      </div>
                    </div>
                  )}

                  {dataType.id === "ICEBERG" && (
                    <IcebergConnectionForm
                      selectedConnection={selectedConnection}
                      setSelectedConnection={setSelectedConnection}
                      submitted={submitted}
                      isAddSelected={isAddSelected}
                      setSubmitted={setSubmitted}
                      fetchFiles={fetchFiles}
                      filesList={filesList}
                      dispatch={dispatch}
                      connectionLoading={connectionLoading}
                      connectionTesting={connectionTesting}
                      toast={toast}
                      metaLocationPath={metaLocationPath}
                    />
                  )}
                  {dataType.id === "DUCKDB" && (
                    <DuckDBConnectionForm
                      selectedConnection={selectedConnection}
                      setSelectedConnection={setSelectedConnection}
                      submitted={submitted}
                      setSubmitted={setSubmitted}
                      isAddSelected={isAddSelected}
                      duckDBRadio={duckDBRadio}
                      setDuckDBRadio={setDuckDBRadio}
                      toast={toast}
                      filesList={filesList}
                      fetchFiles={fetchFiles}
                      dispatch={dispatch}
                      connectionLoading={connectionLoading}
                      connectionTesting={connectionTesting}
                    />
                  )}
                  {dataType.id === "harddisk" && (
                    <>
                      <div className="row">
                        <div className="col col-md-6">
                          <div className="form-group mb-2">
                            <WizBIInput
                              labelName="Connection Name"
                              className={`${
                                submitted && !selectedConnection.db_conn_name
                                  ? "is-invalid"
                                  : ""
                              }`}
                              controls={{
                                value: selectedConnection.db_conn_name,
                                onChange: (e) => {
                                  setSelectedConnection({
                                    ...selectedConnection,
                                    db_conn_name: e.target.value,
                                  });
                                },
                                id: "db_conn_name",
                                tabindex: 1,
                              }}
                            >
                              {" "}
                              <div className="invalid-feedback">
                                A valid connection name is required!
                              </div>{" "}
                            </WizBIInput>
                          </div>
                          <div className="form-group mb-2">
                            <WizBIInput
                              labelName="Prefix"
                              //  className={`${(submitted && !selectedConnection.lfs_prefix) ? 'is-invalid' : ''}`}
                              controls={{
                                value: selectedConnection.lfs_prefix,
                                onChange: (e) => {
                                  setSelectedConnection({
                                    ...selectedConnection,
                                    lfs_prefix: e.target.value,
                                  });
                                },
                                id: "lfs_prefix",
                                tabindex: 3,
                              }}
                            >
                              {/* <div className='invalid-feedback'>A valid prefix is required!</div>  */}
                            </WizBIInput>
                          </div>
                        </div>
                        <div className="col col-md-6">
                          <div className="form-group mb-2">
                            <WizBIInput
                              labelName="Path"
                              className={`${
                                submitted && !selectedConnection.lfs_path
                                  ? "is-invalid"
                                  : ""
                              }`}
                              controls={{
                                value: selectedConnection.lfs_path,
                                disabled: !isAddSelected,
                                onChange: (e) => {
                                  setSelectedConnection({
                                    ...selectedConnection,
                                    lfs_path: e.target.value,
                                  });
                                },
                                id: "lfs_path",
                                tabindex: 1,
                              }}
                            >
                              {" "}
                              <div className="invalid-feedback">
                                A valid path is required!
                              </div>{" "}
                            </WizBIInput>
                          </div>
                          <div className="form-group mb-2">
                            <WizBIInput
                              labelName="Mount Point / Drive"
                              // className={`${(submitted && !selectedConnection.lfs_mount_point) ? 'is-invalid' : ''}`}
                              controls={{
                                value: selectedConnection.lfs_mount_point,
                                onChange: (e) => {
                                  setSelectedConnection({
                                    ...selectedConnection,
                                    lfs_mount_point: e.target.value,
                                  });
                                },
                                id: "lfs_mount_point",
                                tabindex: 3,
                              }}
                            >
                              {/* <div className='invalid-feedback'>A valid Mount Point / Drive is required!</div>  */}
                            </WizBIInput>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {isAddSelected && (
                          <div className="col-md-3 col-lg-3">
                            <button
                              className="btn bg-wizBi text-white"
                              style={{ width: "150px" }}
                              onClick={() => {
                                if (!selectedConnection.lfs_path) {
                                  return setSubmitted(true);
                                }
                                setSubmitted(false);
                                dispatch(showLoader());
                                fetchLocalFiles(
                                  {
                                    lfs_path: selectedConnection.lfs_path,
                                    lfs_prefix: selectedConnection.lfs_prefix,
                                    lfs_mount_point:
                                      selectedConnection.lfs_mount_point,
                                  },
                                  (resp) => {
                                    dispatch(hideLoader());

                                    if (
                                      !!resp &&
                                      (!!resp.detail || !!resp.message)
                                    ) {
                                      toast.current.show({
                                        severity: "error",
                                        summary: "Error",
                                        detail: resp.detail || resp.message,
                                        life: 3000,
                                      });
                                    } else {
                                      setFilesList(resp);
                                    }
                                  }
                                );
                              }}
                            >
                              Get Local Files
                            </button>
                          </div>
                        )}
                        {isAddSelected && !!filesList.length && (
                          <div className="col-md-7 col-lg-7">
                            <h6 className="mx-3 text-wizBi">CSV files list</h6>
                            <VirtualScroller
                              items={filesList}
                              itemSize={50}
                              itemTemplate={itemTemplate}
                              className="surface-border border-round"
                              style={{ height: "150px" }}
                            />
                          </div>
                        )}

                        {!isAddSelected &&
                          !!selectedConnection.connection_ext?.length && (
                            <div className="col-md-5 col-lg-5">
                              <h6 className="mx-3 text-wizBi">
                                CSV files list
                              </h6>
                              <VirtualScroller
                                items={selectedConnection.connection_ext}
                                itemSize={50}
                                itemTemplate={itemTemplate}
                                className="surface-border border-round"
                                style={{ height: "150px" }}
                              />
                            </div>
                          )}
                      </div>
                    </>
                  )}

                  {dataType.id === "ganalytics" && (
                    <>
                      <div className="row">
                        <div className="col col-md-6">
                          <div className="form-group mb-2">
                            <WizBIInput
                              labelName="Connection Name"
                              className={`${
                                submitted && !selectedConnection.db_conn_name
                                  ? "is-invalid"
                                  : ""
                              }`}
                              controls={{
                                value: selectedConnection.db_conn_name,
                                onChange: (e) => {
                                  setSelectedConnection({
                                    ...selectedConnection,
                                    db_conn_name: e.target.value,
                                  });
                                },
                                id: "db_conn_name",
                                tabindex: 1,
                              }}
                            >
                              {" "}
                              <div className="invalid-feedback">
                                A valid connection name is required!
                              </div>{" "}
                            </WizBIInput>
                          </div>
                          <div className="form-group mb-2">
                            <WizBIInput
                              labelName="Auth JSON"
                              className={`${
                                submitted && !selectedConnection.ga_auth_json
                                  ? "is-invalid"
                                  : ""
                              }`}
                              controls={{
                                value: selectedConnection.ga_auth_json,
                                disabled: !isAddSelected,
                                onChange: (e) => {
                                  setSelectedConnection({
                                    ...selectedConnection,
                                    ga_auth_json: e.target.value,
                                  });
                                },
                                onBlur: (e) => {
                                  try {
                                    const parsedJson = JSON.parse(
                                      e.target.value
                                    );
                                    console.log(parsedJson);
                                  } catch (error) {
                                    toast.current.show({
                                      severity: "error",
                                      summary: "Error",
                                      detail:
                                        "Invalid JSON from Auth JSON:" + error,
                                      life: 3000,
                                    });
                                    console.error();
                                  }
                                },
                                id: "ga_auth_json",
                                tabindex: 3,
                                type: "textarea",
                              }}
                            >
                              <div className="invalid-feedback">
                                A valid auth JSON is required!
                              </div>
                            </WizBIInput>
                          </div>
                        </div>
                        <div className="col col-md-6">
                          <div className="form-group mb-2">
                            <WizBIInput
                              labelName="Property Id"
                              className={`${
                                submitted && !selectedConnection.ga_property_id
                                  ? "is-invalid"
                                  : ""
                              }`}
                              controls={{
                                value: selectedConnection.ga_property_id,
                                disabled: !isAddSelected,
                                onChange: (e) => {
                                  setSelectedConnection({
                                    ...selectedConnection,
                                    ga_property_id: e.target.value,
                                  });
                                },
                                id: "ga_property_id",
                                tabindex: 2,
                              }}
                            >
                              {" "}
                              <div className="invalid-feedback">
                                A valid Property Id is required!
                              </div>{" "}
                            </WizBIInput>
                          </div>
                          {isAddSelected && (
                            <div className="form-group mt-5">
                              <button
                                className="btn bg-wizBi text-white"
                                disabled={!selectedConnection.ga_property_id}
                                style={{ width: "150px" }}
                                onClick={() => {
                                  setSubmitted(false);
                                  dispatch(showLoader());
                                  getDimensions(selectedConnection);
                                }}
                              >
                                Get Dimensions
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="d-flex">
                          {!!dimensions.length && (
                            <div className="py-2">
                              <h6 className="mx-3 text-wizBi">Dimensions</h6>
                              <div className="d-flex">
                                <ListBox
                                  filter
                                  value={selectedDimenson}
                                  itemTemplate={dimensionsTemplate}
                                  options={dimensions}
                                  optionLabel="name"
                                  className="w-full md:w-14rem"
                                  optionValue="code"
                                  listStyle={{
                                    height: "200px",
                                    minWidth: "200px",
                                  }}
                                />
                              </div>
                            </div>
                          )}

                          {!!metrics.length && (
                            <div className="p-2">
                              <h6 className="mx-3 text-wizBi">Metrics</h6>
                              <div className="d-flex">
                                <ListBox
                                  filter
                                  value={selectedMetrics}
                                  itemTemplate={metricsTemplate}
                                  options={metrics}
                                  optionLabel="metric"
                                  className="w-full md:w-14rem"
                                  listStyle={{
                                    height: "200px",
                                    minWidth: "200px",
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </div>

                        {/* {!isAddSelected && !!selectedConnection.connection_ext?.length &&
                                                <>
                                                    <div className="py-2">
                                                        <h6 className="mx-3 text-wizBi">Dimensions</h6>
                                                        <div className="d-flex">
                                                            <ListBox filter value={selectedDimenson}
                                                                itemTemplate={dimensionsTemplate}
                                                                options={selectedConnection.connection_ex} optionLabel="dimension" className="w-full md:w-14rem" listStyle={{ height: '200px', minWidth: '200px' }} />
                                                        </div>
                                                    </div>
                                                    <div className="p-2">
                                                        <h6 className="mx-3 text-wizBi">Metrics</h6>
                                                        <div className="d-flex">
                                                            <ListBox filter value={selectedMetrics}
                                                                itemTemplate={metricsTemplate}
                                                                options={metrics}
                                                                optionLabel="metric" className="w-full md:w-14rem" listStyle={{ height: '200px', minWidth: '200px' }} />
                                                        </div>
                                                    </div></>} */}
                      </div>
                    </>
                  )}
                </>
              </div>
            ) : (
              <>
                {!isAddSelected && (
                  <div className="d-flex justify-content-center align-items-center h-100">
                    <h5 className="text-center">
                      {" "}
                      Please select a connection to see the details
                    </h5>
                  </div>
                )}
              </>
            )}
          </Widget>
        </div>
      </div>
      <Toast ref={toast} />
    </>
  );
};

export default Connections;
