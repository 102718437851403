import React, { useEffect, useRef, useState } from "react";
import Widget from "../../components/Widget/Widget";
import s from "./Audits.module.scss";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../actions/loader";
import { fetchAudits } from "../../api/auditsAPI";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  NavLink,
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import WizBIDropDown from "../../core/WizBIDropDown/WizBIDropDown";
import { Dropdown } from "primereact/dropdown";
import { usePipelines } from "../../hooks/usePipelines";
import { Checkbox } from "primereact/checkbox";
const Audits = (props) => {
  const [loading, setLoading] = useState(true);
  const [auditsList, setAuditsList] = useState([]);
  const toast = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { pipelinesResult } = usePipelines();
  const [filterList, setFilterList] = useState([]);
  const [maxRows, setMaxRows] = useState(5);
  const resetPipelineInfo = {
    name: "",
    description: "description",
    airflow_pipeline_name: "airflow_pipeline_name",
    airflow_pipeline_link: "airflow_pipeline_link",
    status: "",
    source_schema_name: "",
    dest_schema_name: "",
    db_conn_source_id: 0,
    db_conn_dest_id: 0,
  };
  const [pipelineInfo, setPipeLineInfo] = useState(resetPipelineInfo);
  const [showAllData, setShowAllData] = useState(false);

  const onShowAllData = (e) => {
    setShowAllData(e.checked);
    if (e.checked) {
      setFilterList(auditsList);
      setPipeLineInfo(resetPipelineInfo);
      setTimeout(() => handleResize(), 100);
    }
  };
  const getAuditsInfo = () => {
    dispatch(showLoader());
    fetchAudits((resp) => {
      // dispatch(hideLoader());
      if (!!resp && (!!resp.detail || !!resp.message)) {
        dispatch(hideLoader());
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: resp.detail || resp.message,
          life: 3000,
        });
      } else {
        setAuditsList(resp);
        setLoading(false);
        dispatch(hideLoader());
        let filterList = resp;
        const id = parseInt(searchParams.get("pipelineId"));
        setShowAllData(true);
        if (id && resp.length) {
          filterList = resp.filter((job) => job.pipeline_id === id);
          setShowAllData(false);
        }
        setFilterList(filterList);
        setLoading(false, () => {
          dispatch(hideLoader());
        });
        setTimeout(() => handleResize(), 100);
      }
    });
  };

  useEffect(() => {
    if (searchParams.get("pipelineId") && pipelinesResult.length) {
      const info =
        pipelinesResult.find(
          (pInfo) => pInfo.id === parseInt(searchParams.get("pipelineId")),
        ) || pipelineInfo;
      setPipeLineInfo(info);
    }
  }, [pipelinesResult]);

  useEffect(() => {
    getAuditsInfo();
  }, []);

  const handleResize = () => {
    const topPos = document
      .querySelector(".p-datatable-tbody")
      .getBoundingClientRect().top;
    const available = window.innerHeight - topPos;
    setMaxRows(Math.ceil(available / 50 - 2));
  };
  useEffect(() => {
    // Attach the event listener to the window object
    window.addEventListener("resize", handleResize);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const customBodyTemplate = (dTableInfo, props) => {
    let str = "";
    switch (props.field) {
      case "name":
        str = (
          <NavLink
            to={`/app/datawarehouse?pipelineId=${dTableInfo.pipeline_id}`}
          >
            <span className="font-bold">{`${dTableInfo.pipeline.name} _ ${dTableInfo.pipeline.id}`}</span>
          </NavLink>
        );
        break;
      case "startDT":
        let startDT = new Date(dTableInfo.job.start_time);
        str =
          startDT.toLocaleString("en-GB", {
            weekday: "long",
            day: "numeric",
            month: "long",
          }) +
          " " +
          new Intl.DateTimeFormat("en-GB", {
            hour12: true,
            hour: "numeric",
            minute: "numeric",
          }).format(startDT);
        break;
      case "endDT":
        let endDT = new Date(dTableInfo.job.end_time);
        str =
          endDT.toLocaleString("en-GB", {
            weekday: "long",
            day: "numeric",
            month: "long",
          }) +
          " " +
          new Intl.DateTimeFormat("en-GB", {
            hour12: true,
            hour: "numeric",
            minute: "numeric",
          }).format(endDT);
        break;
      case "status":
        str =
          dTableInfo.job.status.toLowerCase().indexOf("success") !== -1 ? (
            <span className={`badge mx-4 wizBi-bg-success`}>
              {dTableInfo.job.status}
            </span>
          ) : (
            <span className={`badge mx-4 wizBi-bg-pending`}>
              {dTableInfo.job.status}
            </span>
          );
        break;
      case "jobs":
        str = (
          <NavLink
            to={`/app/jobs?pipelineId=${dTableInfo.pipeline_id}&jobId=${dTableInfo.job_id}`}
          >
            {dTableInfo.job_id}
          </NavLink>
        );
        break;
    }
    return str;
  };
  const navigate = useNavigate();

  const navToTarget = (url) => {
    navigate(url);
  };
  const filterPipelineInfo = (e) => {
    setShowAllData(false);
    setPipeLineInfo(e.value);
    const filterList = auditsList.filter(
      (job) => job.pipeline_id === e.value.id,
    );
    setFilterList(filterList);
    setTimeout(() => handleResize(), 100);
    navToTarget({
      pathname: "/app/audits",
      search: `?${createSearchParams({
        pipelineId: e.value.id,
      })}`,
    });
  };

  return (
    <>
      <div className={`row ${s.root}`}>
        <div className={`col-md-12 col-lg-12 ${s.wrapper}`}>
          <Widget
            title={
              <>
                <div className="d-flex justify-content-between align-items-center">
                  <h5>Audits</h5>
                  <div className="d-flex align-items-center">
                    <div className="d-flex  mx-4">
                      <Checkbox
                        inputId="showAllData"
                        name="pipelines"
                        value="pipelines"
                        onChange={onShowAllData}
                        checked={showAllData}
                        disabled={
                          !Array.isArray(filterList) || !pipelineInfo.id
                        }
                      />
                      <label htmlFor="showAllData" className="mx-2">
                        Show all data
                      </label>
                    </div>
                    <div className="form-group mx-5" style={{ width: "400px" }}>
                      <WizBIDropDown
                        labelName="Pipeline"
                        panelClass="mb-2 w-100"
                      >
                        <Dropdown
                          filter
                          value={pipelineInfo}
                          style={{ height: "35px" }}
                          className="w-100 d-flex form-control active align-items-center"
                          onChange={(e) => {
                            filterPipelineInfo(e);
                          }}
                          options={pipelinesResult}
                          optionLabel="name"
                          placeholder="Select a Pipeline"
                        />
                      </WizBIDropDown>
                    </div>
                  </div>
                </div>
                {/* <div className="row my-2">
                                <div className="d-flex justify-content-between align-items-center py-2 px-4">
                                   
                                </div>
                            </div> */}
                {/* <Divider /> */}
              </>
            }
            className={`mb-0`}
            bodyClass={`m-0 p-0 ${s.widgetBodyClass}`}
          >
            {" "}
            <div className={`w-100 h-100 p-4`}>
              <DataTable
                value={filterList}
                rowSelection
                selectionMode="single"
                data-pr-classname="h-100"
                emptyMessage={
                  loading ? (
                    <h5 className="d-flex justify-content-center">
                      Loading ...
                    </h5>
                  ) : (
                    <h5 className="d-flex justify-content-center">
                      No Audits available
                    </h5>
                  )
                }
                rows={maxRows}
                paginator
                // onSelectionChange={(e) => console.log(e.value)}
                tableStyle={{ minHeight: "200px" }}
              >
                <Column
                  field="name"
                  header="Pipeline / name"
                  body={customBodyTemplate}
                  sortable
                  sortField="pipeline.name"
                ></Column>
                <Column
                  field="jobs"
                  header="Job ID"
                  body={customBodyTemplate}
                  sortable
                  sortField="job_id"
                ></Column>
                <Column
                  field="startDT"
                  header="Start Date Time"
                  body={customBodyTemplate}
                ></Column>
                <Column
                  field="endDT"
                  header="End Date Time"
                  body={customBodyTemplate}
                ></Column>
                <Column
                  field="status"
                  header="Status"
                  body={customBodyTemplate}
                  sortable
                  sortField="job.status"
                ></Column>
                <Column field="duplicates" header="Duplicates"></Column>
                <Column field="inserts" header="Inserts"></Column>
                <Column field="errors" header="Errors"></Column>
                <Column field="warnings" header="Warnings"></Column>
              </DataTable>
            </div>
          </Widget>
        </div>
      </div>
    </>
  );
};

export default Audits;
