import React, { useState, useEffect } from "react";
import { generateCode, generateQuestions } from "../../api/genai";
import { fetchPipelines } from "../../api/pipeLine";
import { Select, Radio, Button, Input, Row, Col, Modal } from "antd";

import { isArray, isEmpty } from "lodash";
import { CodeExtract } from "./CodeExtract";

const { Option } = Select;

const AIForDataScience = () => {
  const [questions, setQuestions] = useState({});
  const [loading, setLoading] = useState(true);
  const [qType, setQType] = useState("fact");
  const [pipelineOptions, setPipelineOptions] = useState([]);
  const [selectedPipeline, setSelectedPipeline] = useState("");
  const [radioEnabled, setRadioEnabled] = useState(false);
  const [customQuestion, setCustomQuestion] = useState("");
  const [customQuestionAnswer, setCustomQuestionAnswer] = useState("");
  const [customQuestionError, setCustomQuestionError] = useState("");
  const [generateLoading, setGenerateLoading] = useState(false);
  const [generateCodeLoading, setGenerateCodeLoading] = useState(false);
  const [generatedCode, setGeneratedCode] = useState("");
  const [selectedQuestion, setSelectedQuestion] = useState([]);


  const [algorithmEnabled, setAlgorithmEnabled] = useState(false);
  const [algorithmName, setAlgorithmName] = useState("");
  const pipelineQuery = {
    pipeline_status: "active",
    pipeline_type: "ETL",
  };
  const [viewGeneratedCode, setViewVisible] = useState(false);

  const generateTextModal = () => {
    setViewVisible(true);
  };

  const handleModalOk = () => {
    setViewVisible(false);
  };

  const handleModalCancel = () => {
    setViewVisible(false);
  };

  const fetchPipelineOptions = async () => {
    fetchPipelines((data) => {
      isArray(data) && setPipelineOptions(data);
    }, pipelineQuery);
  };

  const fetchQuestions = async (questionInfo) => {
    try {
      setGenerateLoading(true);
      const response = await generateQuestions(questionInfo, qType);
      if (qType === "fact") {
        setQuestions(response?.questions || []);
      } else {
        setCustomQuestionAnswer(response?.answer || "");
        setQuestions([
          {
            customQuestion,
          },
        ]);
      }
    } catch (error) {
      console.error("Error fetching questions:", error);
    } finally {
      setLoading(false);
      setGenerateLoading(false);
    }
  };

  useEffect(() => {
    fetchPipelineOptions();
  }, []);

  useEffect(() => {
    if (selectedPipeline) {
      setRadioEnabled(true);
    } else {
      setRadioEnabled(false);
    }
  }, [selectedPipeline]);

  const handlePipelineChange = (value) => {
    setSelectedPipeline(value);
    setGeneratedCode("");
    setCustomQuestion("");
    setCustomQuestionError("");
    setAlgorithmEnabled(false);
    setQType(null);
    setAlgorithmName("");
    setQuestions([]);
  };

  const handleRadioChange = (e) => {
    setQType(e.target.value);
    setCustomQuestion("");
    setCustomQuestionError("");
    setAlgorithmEnabled(false);
    setGeneratedCode("");
    setAlgorithmName("");
  };

  const handleGenerateClick = () => {
    setLoading(true);
    fetchQuestions({ pipeline_id: selectedPipeline });
  };

  const handleCustomQuestionChange = (e) => {
    const value = e.target.value;
    setCustomQuestion(value);
    setGeneratedCode("");
    if (value.length < 20) {
      setCustomQuestionError("Please enter at least 20 characters");
    } else {
      setCustomQuestionError("");
      qType !== "fact" && setAlgorithmEnabled(true);
    }
  };

  const handleAlgorithmNameChange = (e) => {
    setGeneratedCode("");
    setAlgorithmName(e.target.value);
  };

  const handleQuestionSelection = (e) => {
    setSelectedQuestion(e.target.value);
    setGeneratedCode("");
    if (e.target.value.length > 0) {
      setAlgorithmEnabled(true);
    } else {
      setAlgorithmEnabled(false);
    }
  };

  const handleGenerateCodeClick = async () => {
    setGenerateCodeLoading(true);
    const { response = "" } = await generateCode({
      pipeline_id: selectedPipeline,
      question: selectedQuestion,
      algorithm: algorithmName,
    });
    setGenerateCodeLoading(false);
    setGeneratedCode(response);
  };

  return (
    <div style={{ padding: 20 }}>
      <h5 className="mb-5">
        Generative AI assisted code for advanced data analysis
      </h5>

      <Row gutter={[16, 16]} justify="start" align="middle" className="mb-3">
                <Col span={6}>
                    <h6 htmlFor="engine">Select AI Engine:</h6>
                </Col>
                <Col span={6}>
                    <Select
                        id="engine"
                        placeholder="Select Pipeline"
                        style={{ width: "100%" }}
                        defaultValue={"1"}
                    >
                        {[{id: "1", name: "LLM-ChatGPT-4o"}].map((engine) => (
                                <Option key={engine.id} value={engine.id}>
                                    {engine.name}
                                </Option>
                            ))}
                    </Select>
                </Col>
            </Row>
      <Row gutter={[16, 16]} justify="start" align="middle" className="mb-3">
        <Col span={6}>
          <h6 htmlFor="pipeline">Select Data Warehouse/Data Lake Pipeline:</h6>
        </Col>
        <Col span={6}>
          <Select
            id="pipeline"
            onChange={handlePipelineChange}
            placeholder="Select Pipeline"
            style={{ width: "100%" }}
          >
            {pipelineOptions?.length &&
              pipelineOptions.map((pipeline) => (
                <Option key={pipeline.id} value={pipeline.id}>
                  {pipeline.name}
                </Option>
              ))}
          </Select>
        </Col>
      </Row>

      {radioEnabled && (
        <Row
          gutter={[16, 16]}
          justify="start"
          align="middle"
          className="mb-3"
          style={{ marginTop: 20 }}
        >
          <Col span={6}>
            <h6 htmlFor="pipeline">Select Question Type:</h6>
          </Col>
          <Col span={16}>
            <Radio.Group onChange={handleRadioChange} value={qType}>
              <Radio value="fact">
                Generate 25 questions for analysis based on data warehouse
                schema
              </Radio>
              <Radio value="other">
                Ask a question based on the data warehouse schema
              </Radio>
            </Radio.Group>
          </Col>
        </Row>
      )}

      {qType === "other" && (
        <Row
          gutter={[16, 16]}
          justify="start"
          align="start"
          style={{ marginTop: 10 }}
        >
          <Col span={6} />
          <Col span={4}>
            <Input
              placeholder="Enter your custom question"
              value={customQuestion}
              onChange={handleCustomQuestionChange}
            />
            {customQuestionError && (
              <span style={{ color: "red" }}>{customQuestionError}</span>
            )}
          </Col>
        </Row>
      )}

      {radioEnabled && qType === "fact" && (
        <Row gutter={[16, 16]} justify="start" align="start" className="mb-3">
          <Col span={6} />
          <Col span={8}>
            <Button
              type="primary"
              style={{ marginLeft: 10 }}
              onClick={handleGenerateClick}
              disabled={!isEmpty(questions) && Object.keys(questions).length}
              loading={generateLoading}
            >
              Generate
            </Button>
          </Col>
        </Row>
      )}

      {!loading && qType === "fact" && Object.keys(questions).length > 0 && (
        <Row gutter={[16, 16]} justify="start" align="start" className="mb-3">
          <Col span={6}>
            <h6 htmlFor="genretedQuestions">Generated Questions:</h6>
          </Col>

          <Col span={12}>
            <div
              style={{
                maxHeight: "26vh",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <Radio.Group onChange={handleQuestionSelection}>
                {Object.entries(questions).map(([id, question]) => (
                  <Radio
                    key={id}
                    value={question}
                    className="mb-1"
                    style={{ width: "100%" }}
                  >
                    {question}
                  </Radio>
                ))}
              </Radio.Group>
            </div>
          </Col>
        </Row>
      )}

      {algorithmEnabled && (
        <Row
          gutter={[16, 16]}
          justify="start"
          align="start"
          className="mb-3 pt-2 pb-2"
        >
          <Col span={6}>
            <h6 htmlFor="chooseAlgo">Choose Algorithm:</h6>
          </Col>
          <Col span={16}>
            <Radio.Group
              onChange={handleAlgorithmNameChange}
              value={algorithmName}
            >
              <Radio value="linearRegression">Linear Regression</Radio>
              <Radio value="naiveBayes">Naive Bayes</Radio>
              <Radio value="randomForest">Random Forest</Radio>
              <Radio value="customAlgorithm">
                <Input
                  placeholder="Enter custom algorithm name"
                  onChange={handleAlgorithmNameChange}
                  style={{ width: 200 }}
                />
              </Radio>
            </Radio.Group>
          </Col>
        </Row>
      )}

      {!(
        (algorithmName !== "custom" && !algorithmName) ||
        (algorithmName === "custom" && !algorithmName)
      ) && (
          <Row
            gutter={[16, 16]}
            justify="start"
            align="middle"
            className="mb-3"
            style={{ marginTop: 20, marginBottom: 20 }}
          >
            <Col span={3} offset={6}>
              <Button
                type="primary"
                onClick={handleGenerateCodeClick}
                size="medium"
                loading={generateCodeLoading}
                disabled={generatedCode}
              >
                Generate Code
              </Button>
            </Col>
            <Col span={3} className="ml-1">
              <Button
                size="medium"
                type="primary"
                onClick={generateTextModal}
                disabled={!generatedCode}
              >
                View Generated Code
              </Button>
            </Col>
          </Row>
        )}

      <Modal
        title="Generated Code"
        visible={viewGeneratedCode}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        width={"70%"}
        footer={[
          <Button key="cancel" onClick={handleModalCancel}>
            Cancel
          </Button>,
        ]}
      >
        {CodeExtract(generatedCode)}
      </Modal>
    </div>
  );
};

export default AIForDataScience;
