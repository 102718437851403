import React from "react";

class HomeIcon extends React.Component {
  render() {
    return (
      <svg
        className={this.props.className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.8252 16.6667H13.3335V10.8333C13.3335 10.3725 12.9602 10 12.5002 10H7.50018C7.03935 10 6.66685 10.3725 6.66685 10.8333V16.6667H4.16685L4.17185 9.65251L9.99852 3.69334L15.8335 9.68667L15.8252 16.6667ZM8.33352 16.6667H11.6668V11.6667H8.33352V16.6667ZM17.0202 8.4875L10.596 1.9175C10.2818 1.59667 9.71852 1.59667 9.40435 1.9175L2.97935 8.48834C2.67518 8.80084 2.50018 9.2375 2.50018 9.68667V16.6667C2.50018 17.5858 3.20602 18.3333 4.07352 18.3333H7.50018H12.5002H15.926C16.7935 18.3333 17.5002 17.5858 17.5002 16.6667V9.68667C17.5002 9.2375 17.3252 8.80084 17.0202 8.4875Z"
        />
        <mask
          id="home"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="2"
          y="1"
          width="16"
          height="18"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.8252 16.6667H13.3335V10.8333C13.3335 10.3725 12.9602 10 12.5002 10H7.50018C7.03935 10 6.66685 10.3725 6.66685 10.8333V16.6667H4.16685L4.17185 9.65251L9.99852 3.69334L15.8335 9.68667L15.8252 16.6667ZM8.33352 16.6667H11.6668V11.6667H8.33352V16.6667ZM17.0202 8.4875L10.596 1.9175C10.2818 1.59667 9.71852 1.59667 9.40435 1.9175L2.97935 8.48834C2.67518 8.80084 2.50018 9.2375 2.50018 9.68667V16.6667C2.50018 17.5858 3.20602 18.3333 4.07352 18.3333H7.50018H12.5002H15.926C16.7935 18.3333 17.5002 17.5858 17.5002 16.6667V9.68667C17.5002 9.2375 17.3252 8.80084 17.0202 8.4875Z"
          />
        </mask>
        <g mask="url(#home)">
          <rect width="20" height="20" />
        </g>
      </svg>
    );
  }
}

export default HomeIcon;
