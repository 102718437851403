const LogoIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      version="1.0"
      width="40"
      height="40"
      viewBox="0 0 387.000000 350.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <metadata>
        Created by potrace 1.16, written by Peter Selinger 2001-2019
      </metadata>
      <g
        transform="translate(0.000000,350.000000) scale(0.100000,-0.100000)"
        fill="#498084"
        stroke="none"
      >
        <path d="M2648 3194 c-23 -19 -63 -53 -88 -73 -25 -21 -79 -66 -120 -100 -41 -34 -91 -75 -110 -91 l-35 -29 98 -1 c53 0 97 -1 98 -2 0 -2 3 -565 6 -1253 l5 -1250 21 -23 c19 -21 28 -22 166 -22 156 0 174 5 200 57 8 16 11 367 11 1257 l0 1236 97 0 c108 0 106 -7 16 67 -21 17 -48 39 -60 50 -12 10 -32 27 -45 37 -12 11 -37 31 -53 45 -17 14 -50 41 -73 59 -22 19 -51 42 -63 53 -12 10 -23 19 -25 19 -1 0 -22 -16 -46 -36z" />
        <path d="M803 2225 c-18 -20 -19 -2055 -1 -2073 9 -9 254 -12 1040 -12 l1028 0 17 26 c15 24 15 29 2 53 l-15 26 -973 3 c-700 1 -978 5 -988 13 -11 10 -13 181 -13 982 0 912 -1 971 -17 983 -23 17 -65 17 -80 -1z" />
        <path d="M2019 2214 l-24 -26 -3 -885 -2 -886 25 -33 26 -34 153 0 c151 0 153 0 177 26 l24 26 0 893 0 893 -24 26 c-24 26 -26 26 -176 26 -150 0 -152 0 -176 -26z" />
        <path d="M1518 1928 c-17 -21 -18 -73 -18 -779 l0 -758 29 -20 c26 -19 44 -21 166 -21 137 0 167 6 192 39 10 12 12 192 13 763 l0 748 -22 25 c-21 25 -23 25 -182 25 -154 0 -162 -1 -178 -22z" />
        <path d="M1029 1721 l-24 -19 0 -657 0 -656 25 -19 c22 -17 43 -20 176 -20 150 0 151 0 178 26 l26 27 0 642 0 642 -26 27 c-27 26 -28 26 -179 26 -136 0 -155 -2 -176 -19z" />
        <path d="M260 510 l0 -60 196 0 c175 0 195 2 190 16 -3 9 -6 36 -6 60 l0 44 -190 0 -190 0 0 -60z" />
        <path d="M3240 508 l0 -63 190 3 190 3 0 59 0 60 -190 0 -190 0 0 -62z" />
        <path d="M260 212 l0 -62 191 2 192 3 0 55 0 55 -44 2 c-24 2 -110 4 -192 5 l-147 3 0 -63z" />
        <path d="M3240 213 l0 -63 190 0 190 0 0 63 0 62 -190 0 -190 0 0 -62z" />
      </g>
    </svg>
  );
};

export default LogoIcon;
