import React from "react";
import IcebergIcon from "../../assets/iceberg.png";
import s from "./Connections.module.scss";
import FormGroup from "./FormGroup";
import ConnectionField from "./ConnectionField";
import { VirtualScroller } from "primereact/virtualscroller";

const IcebergConnectionForm = ({
  itemTemplate,
  selectedConnection,
  setSelectedConnection,
  submitted,
  isAddSelected,
  filesList,
  connectionLoading,
  connectionTesting,
  metaLocationPath
}) => (
  <>
    <img src={IcebergIcon} alt="Iceberg" className={s.icebergicon} />
    <div className="row">
      <FormGroup>
        <ConnectionField
          labelName="Connection Name"
          value={selectedConnection.db_conn_name}
          onChange={(e) => {
            setSelectedConnection({
              ...selectedConnection,
              db_conn_name: e.target.value,
            });
          }}
          id="db_conn_name"
          submitted={submitted}
          isInvalid={submitted && !selectedConnection.db_conn_name}
          tabIndex={1}
        >
          <div className="invalid-feedback">
            A valid connection name is required!
          </div>
        </ConnectionField>
        {isAddSelected && (
          <ConnectionField
            labelName="Secret Access Key"
            value={selectedConnection.s3_secret_access_key}
            onChange={(e) => {
              setSelectedConnection({
                ...selectedConnection,
                s3_secret_access_key: e.target.value,
              });
            }}
            id="s3_secret_access_key"
            submitted={submitted}
            isInvalid={submitted && !selectedConnection.s3_secret_access_key}
            tabIndex={3}
          >
            <div className="invalid-feedback">
              A valid Secret Access Key is required!
            </div>
          </ConnectionField>
        )}
        <ConnectionField
          labelName="Bucket Path"
          value={selectedConnection.s3_bucket_path}
          onChange={(e) => {
            setSelectedConnection({
              ...selectedConnection,
              s3_bucket_path: e.target.value,
            });
          }}
          id="s3_bucket_path"
          submitted={submitted}
          isInvalid={false}
          tabIndex={5}
        />
         <ConnectionField
          labelName="Database Name"
          value={selectedConnection.iceberg_database}
          onChange={(e) => {
            setSelectedConnection({
              ...selectedConnection,
              iceberg_database: e.target.value,
            });
          }}
          id="iceberg_database"
          submitted={submitted}
          isInvalid={submitted && !selectedConnection.iceberg_database}
          tabIndex={1}
        >
          <div className="invalid-feedback">
            A valid Database name is required!
          </div>
        </ConnectionField>
      </FormGroup>
      <FormGroup>
        {isAddSelected && (
          <ConnectionField
            labelName="Access Key ID"
            value={selectedConnection.s3_access_key_id}
            onChange={(e) => {
              setSelectedConnection({
                ...selectedConnection,
                s3_access_key_id: e.target.value,
              });
            }}
            id="s3_access_key_id"
            submitted={submitted}
            isInvalid={submitted && !selectedConnection.s3_access_key_id}
            tabIndex={1}
          >
            <div className="invalid-feedback">
              A valid access key id is required!
            </div>
          </ConnectionField>
        )}
        <ConnectionField
          labelName="Bucket"
          value={selectedConnection.s3_bucket}
          onChange={(e) => {
            setSelectedConnection({
              ...selectedConnection,
              s3_bucket: e.target.value,
            });
          }}
          id="s3_bucket"
          submitted={submitted}
          isInvalid={submitted && !selectedConnection.s3_bucket}
          tabIndex={3}
        >
          <div className="invalid-feedback">A valid bucket is required!</div>
        </ConnectionField>
        <ConnectionField
          labelName="Bucket Region"
          value={selectedConnection.s3_bucket_region}
          onChange={(e) => {
            setSelectedConnection({
              ...selectedConnection,
              s3_bucket_region: e.target.value,
            });
          }}
          id="s3_bucket_region"
          submitted={submitted}
          isInvalid={submitted && !selectedConnection.s3_bucket_region}
          tabIndex={5}
        >
          <div className="invalid-feedback">
            A valid bucket region is required!
          </div>
        </ConnectionField>
        <ConnectionField
          labelName="Table Name"
          value={selectedConnection.iceberg_table}
          onChange={(e) => {
            setSelectedConnection({
              ...selectedConnection,
              iceberg_table: e.target.value,
            });
          }}
          id="iceberg_table"
          submitted={submitted}
          isInvalid={submitted && !selectedConnection.iceberg_table}
          tabIndex={1}
        >
          <div className="invalid-feedback">
            A valid table name is required!
          </div>
        </ConnectionField>
      </FormGroup>
    </div>
    
    {metaLocationPath && <div style={{marginBottom: "15px"}}>Meta Location Path : {metaLocationPath}</div>}
    <div className="row">
    <div className="col">
        <button
          className="btn bg-wizBi text-white"
          disabled={connectionLoading || ( !selectedConnection.s3_access_key_id ||
            !selectedConnection.s3_secret_access_key ||
            !selectedConnection.s3_bucket ||
            !selectedConnection.s3_bucket_region)}
          onClick={() => {
            connectionTesting(selectedConnection.id, true, {
              aws_access_key: selectedConnection.s3_access_key_id,
              aws_secret_key: selectedConnection.s3_secret_access_key,
              s3_bucket: selectedConnection.s3_bucket,
              s3_bucket_path: selectedConnection.s3_bucket_path,
              s3_region: selectedConnection.s3_bucket_region,
              iceberg_table: selectedConnection.iceberg_table,
              iceberg_database: selectedConnection.iceberg_database
            });
          }}
        >
          {connectionLoading && (
            <span
              className="spinner-border spinner-border-sm mx-2"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          Test Connection
        </button>
      </div>
      {isAddSelected && !!filesList.length && (
        <div className="col-md-7 col-lg-7">
          <h6 className="mx-3 text-wizBi">CSV files list</h6>
          <VirtualScroller
            items={filesList}
            itemSize={50}
            itemTemplate={itemTemplate}
            className="surface-border border-round"
            style={{ height: "150px" }}
          />
        </div>
      )}
      {!isAddSelected && !!selectedConnection.connection_ext?.length && (
        <div className="col-md-5 col-lg-5">
          <h6 className="mx-3 text-wizBi">CSV files list</h6>
          <VirtualScroller
            items={selectedConnection.connection_ext}
            itemSize={50}
            itemTemplate={itemTemplate}
            className="surface-border border-round"
            style={{ height: "150px" }}
          />
        </div>
      )}
    </div>
  </>
);

export default IcebergConnectionForm;
