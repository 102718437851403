import { useEffect, useState } from "react";
import { hideLoader, showLoader } from "../actions/loader";
import { useDispatch } from "react-redux";
import { fetchConnections } from "../api/connection";

function useConnections() {
  const [connectionResult, setConnectionResult] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(showLoader());
    fetchConnections((resp) => {
      dispatch(hideLoader());
      if (!!resp && (!!resp.detail || !!resp.message)) {
      } else {
        setConnectionResult(resp);
      }
    });
  }, []);

  return { connectionResult };
}

export { useConnections };
