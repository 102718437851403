import React from "react";

class ArrowRightIcon extends React.Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.0"
        className={this.props.className}
        width="80.000000pt"
        viewBox="0 0 304.000000 76.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <metadata>
          Created by potrace 1.16, written by Peter Selinger 2001-2019
        </metadata>
        <g
          transform="translate(0.000000,76.000000) scale(0.100000,-0.100000)"
          fill="#498084"
          stroke="none"
        >
          <path d="M2440 621 l0 -51 -955 0 -955 0 0 -190 0 -190 955 0 954 0 3 -55 3 -55 237 147 c131 82 238 150 238 153 -1 7 -449 284 -466 288 -11 3 -14 -9 -14 -47z m259 -116 c105 -66 190 -123 189 -127 -3 -7 -415 -268 -424 -268 -2 0 -4 23 -4 50 l0 50 -960 0 -960 0 0 170 0 170 960 0 960 0 0 50 c0 61 -27 71 239 -95z" />
          <path d="M170 380 l0 -190 35 0 35 0 0 190 0 190 -35 0 -35 0 0 -190z m60 0 l0 -170 -25 0 -25 0 0 170 0 170 25 0 25 0 0 -170z" />
          <path d="M284 557 c-2 -7 -3 -91 -2 -187 l3 -175 93 -3 92 -3 0 191 0 190 -90 0 c-65 0 -92 -4 -96 -13z m176 -177 l0 -170 -80 0 -80 0 0 170 0 170 80 0 80 0 0 -170z" />
        </g>
      </svg>
    );
  }
}

export default ArrowRightIcon;
